import {v4 as uuidv4} from "uuid";

export class ApprovalMessage {
    datetime = ""
    message = ""

    vForKey = `approval-message-${this.datetime}-${uuidv4()}`

    static fromJson(obj: any): ApprovalMessage {
        const am = new ApprovalMessage()

        am.datetime = obj.datetime
        am.message = obj.message

        return am
    }

    static fromJsonArrToArr(objArr: Array<any>): ApprovalMessage[] {
        const arr = [] as ApprovalMessage[]
        objArr.forEach(obj => {
            arr.push(this.fromJson(obj))
        })
        return arr
    }
}
