
import {defineComponent, onMounted, onUnmounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import {findLogoutMessageByKey, subPaths} from "@/utils/consts";
import {loadingOverlayStateStore} from "@/stores/loading-overlay-state-store";

export default defineComponent({
    name: "LoggedOut",
    setup() {
        const logoutMessage = ref<string>(""),
            dots = ref<string>(""),
            sec = ref<number>(0)
        const route = useRoute(),
            router = useRouter()

        let intervalId = 0

        const startTransitionTimer = () => {
            sec.value = 5
            intervalId = window.setInterval(() => {
                sec.value--
                dots.value += "."
                if (sec.value <= 0) {
                    window.clearInterval(intervalId)
                    router.push(subPaths.LOGIN)
                }
            }, 1000)
        }

        const onClickLink = () => {
            window.clearInterval(intervalId)
            router.push(subPaths.LOGIN)
        }

        onMounted(() => {
            const logoutParam = route.params.logout_message as string
            if (logoutParam) {
                logoutMessage.value = findLogoutMessageByKey(logoutParam)
            } else {
                logoutMessage.value = ""
            }
            startTransitionTimer()
            loadingOverlayStateStore.commit("fadeout")
        })

        onUnmounted(() => {
            window.clearInterval(intervalId)
        })

        return {
            logoutMessage,
            dots,
            sec,
            onClickLink,
        }
    }
})
