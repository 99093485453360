
import {defineComponent, PropType} from "vue";
import DialogFrame from "@/components/DialogFrame.vue";

export default defineComponent({
    name: "PasswordForgetDialog",
    components: {
        DialogFrame,
    },
    props: {
        show: {
            type: Boolean,
            required: true
        },
        closeDialog: {
            type: Function as PropType<() => void>,
            required: true
        }

    }
})
