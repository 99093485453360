import {baseDomain} from "@/utils/consts";
import {authStore} from "@/stores/auth-store";
import {InvoiceProcessStatus} from "@/data-models/invoice-process-status";

export type invoiceStatusCheckerProcessFuncType = (ivProcess: InvoiceProcessStatus) => void
export type invoiceStatusCheckerErrorFuncType = (msg: string) => void

export class InvoiceStatusChecker {

    private static wsUrl = "wss" + baseDomain + ":9002"

    private ws: WebSocket|null = null

    onProcessStatus: invoiceStatusCheckerProcessFuncType = (ivProcess: InvoiceProcessStatus) => {
        //
    }

    onError: invoiceStatusCheckerErrorFuncType =  (msg: string) => {
        //
    }

    private restartAfterClose = false

    start (): void {

        if (this.ws) {
            this.restartAfterClose = true
            this.ws.close()
        } else {
            this.startCore()
        }
    }

    private startCore(): void {
        this.ws = new WebSocket(InvoiceStatusChecker.wsUrl)
        this.ws.onopen = ev => {
            console.log("invoiceStatusChecker::ws.onopen")

            if (this.ws) {
                this.ws.send(JSON.stringify({
                    token: authStore.state.token
                }))
            }
        }

        this.ws.onerror = ev => {
            console.log("ws.onerror", ev)
        }

        this.ws.onmessage = ev => {

            const msgObj = JSON.parse(ev.data)

            // console.log("invoiceStatusChecker: ws.onmessage:", msgObj)

            if (msgObj.message) {
                // トークン検証に失敗するとメッセージが返ってくる。
                console.warn(`invoiceStatusChecker::error message: ${msgObj.message}`)
                this.onError(msgObj.message)
            } else {
                const ivProcess = InvoiceProcessStatus.fromJson(msgObj)
                this.onProcessStatus(ivProcess)
            }
        }

        this.ws.onclose = ev => {
            console.log("invoiceStatusChecker::ws.closed")

            if (this.restartAfterClose) {
                this.restartAfterClose = false
                console.log("invoiceStatusChecker::restartAfterClose")
                this.startCore()
            }
        }
    }

    stop (): void {
        if (this.ws) {
            this.ws.close()
            this.ws = null
        }
    }
}

