
import {defineComponent, onMounted, PropType, ref, watch} from "vue";
import {backendApi} from "@/utils/backend-api";
import {useRoute, useRouter} from "vue-router";
import {logoutMessages, subPaths} from "@/utils/consts";
import {PaymentAmount} from "@/data-models/payment-amount";
import {PaymentHistory} from "@/data-models/payment-history";
import ApplyPrepaymentDialog from "@/components/SubmitPrepaymentDialog.vue";
import {amountToString} from "@/utils/string-utils";
import {Staff} from "@/data-models/staff";
import {authStore} from "@/stores/auth-store";
import {authLevels} from "@/data-models/auth";
import {DailyRecord, paymentTypes} from "@/data-models/daily-record";
import {addMonths, format, isSameMonth} from "date-fns";
import {loadingOverlayStateStore} from "@/stores/loading-overlay-state-store";
import {FontSizes} from "@/utils/font-sizes";
import Breadcrumbs, {ancestryType} from "@/components/Breadcrumbs.vue";
import ja from "date-fns/locale/ja"
import {toastStore} from "@/stores/toast-store";

// 20220316 個人ページの表示を1か月分とする
// 管理スタッフは今月分と前月分の履歴を切り替えて見れるようにする
// 配布スタッフは今月分だけが見れればよい
// 20220326 管理スタッフはすべてのパンくずリスト。配布スタッフは自分のマイページまで戻れるパンくずリスト

export default defineComponent({
    name: "Prepayment",
    components: {Breadcrumbs, ApplyPrepaymentDialog},
    setup() {

        const staffId = ref<number>(-1),
            dailyRecordsForTable = ref<DailyRecord[]>([] as DailyRecord[]), // 履歴テーブル用。履歴テーブルは先月、概要欄は今月ということがあるので別個に計算する。
            showSubmitDialog = ref<boolean>(false),
            prepaymentBalance = ref<number>(0),
            compensationSum = ref<number>(0),
            prepaidSum = ref<number>(0),
            staff = ref<Staff>(new Staff()),
            ancestries = ref<ancestryType[]>([] as ancestryType[]),
            prepaymentMonthText = ref<string>(""),
            recordTableMonthText = ref<string>(""),
            backwardMonthCountText = ref<string>("0"),
            // 月初日の0～11時は前の月の情報を表示するため、1となる。
            // backwardMonthCountForOverview = ref<number>(1), // TEST: 1にしておくと読み込み時に前月の情報を読み込みます。
            backwardMonthCountForOverview = ref<number>(0)


        const route = useRoute(),
            router = useRouter()

        const update = async () => {

            updatePrepaymentMonthText()

            const staffResp = await backendApi.loadStaffById(staffId.value)

            if (staffResp.isAuthorized) {
                staff.value = Staff.fromJson(staffResp.value)

                updateAncestries()
                await updateHistoryTable(parseInt(backwardMonthCountText.value))
                await updatePrepaymentOverview()

            } else {
                await router.push({
                    path: subPaths.LOGGED_OUT,
                    query: {
                        logout_message: logoutMessages.TOKEN_EXPIRED.key
                    }
                })
            }
        }

        const updateAncestries = () => {
            ancestries.value = [
                {
                    name: staff.value.displayName + "さん",
                    pathObj: {
                        path: subPaths.MY_PAGE,
                        query: {
                            staff_id: staff.value.staffId,
                        },
                    }
                }
            ]

            if (authStore.getters.authLevel !== authLevels.DIST_STAFF) {
                ancestries.value.unshift({
                    name: staff.value.branch,
                    pathObj: {
                        path: subPaths.BRANCH_PAGE,
                        query: {
                            name: staff.value.branch
                        }
                    }
                })
                ancestries.value.unshift({
                    name: "ホーム",
                    pathObj: subPaths.HOME
                })
            }
        }

        onMounted(async () => {

            const stId = route.query.staff_id as string
            if (stId) {
                staffId.value = parseInt(stId)
                startWatchTime()
                await update()
            }

            loadingOverlayStateStore.commit("fadeout")
        })

        const updatePrepaymentMonthText = () => {
            const prepaymentMonth = addMonths(new Date(), -backwardMonthCountForOverview.value)
            prepaymentMonthText.value = format(prepaymentMonth, "MMM", { locale:ja })
            console.log("updatePrepaymentMonthText:", prepaymentMonthText.value)
        }

        const updateBackwardMonthCountForOverview = () => {

            let count = 0

            const now = new Date()
            if (now.getDate() === 1) {
                const hour = now.getHours()
                if (0 <= hour && hour <= 10) {
                    count = 1
                }
            }

            backwardMonthCountForOverview.value = count
        }

        // 概要欄のための計算。履歴テーブルは別個に更新
        const updatePrepaymentOverview = async () => {

            const currMonthAmountResp = await backendApi.inquirePaymentAmounts(staffId.value, backwardMonthCountForOverview.value)
            const currMonthHistoryResp = await backendApi.inquirePaymentHistories(staffId.value, backwardMonthCountForOverview.value)

            if (currMonthAmountResp.isAuthorized && currMonthHistoryResp.isAuthorized) {

                const currMonthPayAmounts = PaymentAmount.fromJsonArrToArr(currMonthAmountResp.value)
                const currMonthHistories = PaymentHistory.fromJsonArrToArr(currMonthHistoryResp.value)

                const pAmountRecords = DailyRecord.fromPaymentAmounts(currMonthPayAmounts),
                        pHistRecords = DailyRecord.fromPaymentHistories(currMonthHistories)

                const records = pAmountRecords.concat(pHistRecords)

                compensationSum.value = 0
                prepaidSum.value = 0

                records.forEach(record => {
                    if (record.paymentType === paymentTypes.COMPENSATION) {
                        compensationSum.value += record.amount
                    } else if (record.paymentType === paymentTypes.ADVANCE_PAYMENT) {
                        prepaidSum.value += record.amount
                    }
                })

                prepaymentBalance.value = compensationSum.value * 0.8 - prepaidSum.value

            } else {
                await router.push({
                    path: subPaths.LOGGED_OUT,
                    query: {
                        logout_message: logoutMessages.TOKEN_EXPIRED.key
                    }
                })
            }
        }

        const updateHistoryTable = async (backwardMonthCount: number) => {
            const amountResp = await backendApi.inquirePaymentAmounts(staffId.value, backwardMonthCount)
            const historyResp = await backendApi.inquirePaymentHistories(staffId.value, backwardMonthCount)

            if (amountResp.isAuthorized && historyResp.isAuthorized) {
                const payAmounts = PaymentAmount.fromJsonArrToArr(amountResp.value)
                const histories = PaymentHistory.fromJsonArrToArr(historyResp.value)

                dailyRecordsForTable.value.splice(0)

                const amountRecords = DailyRecord.fromPaymentAmounts(payAmounts),
                        histRecords = DailyRecord.fromPaymentHistories(histories)

                dailyRecordsForTable.value = amountRecords.concat(histRecords)
                dailyRecordsForTable.value.sort((a, b) => {
                    return a.date.getTime() - b.date.getTime()
                })

                const monthForTable = addMonths(new Date(), -backwardMonthCount)
                recordTableMonthText.value = format(monthForTable, "MMM", { locale:ja })

            } else {
                await router.push({
                    path: subPaths.LOGGED_OUT,
                    query: {
                        logout_message: logoutMessages.TOKEN_EXPIRED.key
                    }
                })
            }
        }

        watch(() => backwardMonthCountText.value, () => {
            console.log("backwardMonthCount.value")
            updateHistoryTable(parseInt(backwardMonthCountText.value))
        })

        const showPrepaymentUI = (): boolean => {
            // 配布スタッフ本人ユーザーかスーパーアドミンだけが可能

            console.log("showPrepaymentUI")
            let isMyUserPage = false
            try {
                console.log(authStore.state.auth.userId)
                const userIdNum = parseInt(authStore.state.auth.userId)
                isMyUserPage = userIdNum === staffId.value
            } catch (e) {
                console.log(e)
            }
            return authStore.getters.authLevel === authLevels.ADMIN
                    || authStore.getters.authLevel === authLevels.ACCOUNTING_ADMIN
                    || isMyUserPage
        }

        const canMakePrepayment = ():boolean => {
            return compensationSum.value * 0.8 - prepaidSum.value > 0
        }

        const makePrepaymentButtonText = (): string => {
            if (canMakePrepayment()) {
                return "前払いを申し込む"
            } else {
                return "前払い可能残高がありません"
            }
        }

        const onClickApplyPrepayment = () => {
            showSubmitDialog.value = true
        }

        const onRequestCloseApplyDialog = () => {
            showSubmitDialog.value = false
        }

        const onFinishSubmitPrepayment = async (success: boolean) => {
            if (success) {
                await update()
            }
            showSubmitDialog.value = false
        }

        /**
         *月初日の0～11時は前月の前払い情報を表示する。
         */
        const startWatchTime = () => {
            setInterval(() => {
                updateBackwardMonthCountForOverview()
            }, 1000)

            // TEST:
            // backwardMonthCountForOverview.valueを読み込み時に1に設定
            // timeoutを5秒（5000）にセットする。
            // ページを読み込み時は前月の前払い情報が表示され、5秒後に今月の情報に切り替わる。
        }

        watch(() => backwardMonthCountForOverview.value, async () => {
            await updatePrepaymentOverview()
            updatePrepaymentMonthText()
            toastStore.commit("post", {
                message: "前払い情報が更新されました。"
            })
        })

        return {
            staffId,
            dailyRecords: dailyRecordsForTable,
            canMakePrepayment,
            makePrepaymentButtonText,
            showSubmitDialog,
            onClickApplyPrepayment,
            onRequestCloseApplyDialog,
            prepaymentBalance,
            amountToString,
            prepaidSum,
            compensationSum,
            onFinishSubmitPrepayment,
            staff,
            showPrepaymentUI,
            FontSizes,
            ancestries,
            authStore,
            authLevels,
            prepaymentMonthText,
            recordTableMonthText,
            backwardMonthCountText,
        }
    }
})
