import {addDays, addMilliseconds, addMonths, addSeconds, format, startOfDay, startOfMonth} from "date-fns";
import {dateFormat} from "@/utils/consts";

export const calcStartDate = (today: Date): Date => {
    let startDate = startOfMonth(today)
    // if (today.getDate() < 25) {
    //     // 今月の通常支払日よりも前なので、前月の始まり日が期間の開始日となる
    //     startDate = addMonths(startDate, -1)
    // }
    // 前月の1日を始まりとする
    startDate = addMonths(startDate, -1)
    // console.log(format(startDate, dateFormat))
    return startDate
}

export const calcEndDate = (): Date => {
    // 本日に1日を足した明日の0時マイナス1秒
    // return startOfDay(addDays(new Date(), 1))
    return addMilliseconds(startOfDay(addDays(new Date(), 1)), -1)
}

export const toDurationString = (ms: number): string => {

    if (!isFinite(ms)) {
        return "---"
    }

    const sec = Math.floor(ms / 1000) % 60,
        min = Math.floor(ms / (60 * 1000)) % 60,
        hour = Math.floor(ms / (3600 * 1000))

    let str = ""
    if (hour > 0) {
        str += `${hour}時間`
    }

    if (str.length > 0) {
        str += `${min.toString().padStart(2, "0")}分`
    } else {
        if (min > 0) {
            str += `${min}分`
        }
    }

    if (str.length > 0) {
        str += `${sec.toString().padStart(2, "0")}秒`
    } else {
        str += `${sec}秒`
    }
    return str
}

export const toYearMonthStr = (date: Date): string => {
    return format(date, "yyyy/MM")
}