import { parse, format } from "date-fns";
import {dateFormat, datetimeFormat} from "@/utils/consts";
import {DateItem} from "@/data-models/daily-record";


export class PaymentAmount implements DateItem{
    id = -1                     // "Id
    postingDate = new Date()    // "posting_date": "2021/12/11 00:00:00",
    staffId = -1                // "staff_id",
    productId = -1              // "product_id",
    productName = ""            // "product_name"
    areaAddress = ""            // "area_address"
    place = ""                  // "place": "愛住町/5種/0.7円",
    plannedCount = -1           // "planned_count": 500,
    changedCount = -1           // "changed_count": 0,
    adjustPrice = 0             // "adjust_price": 0,
    areaId = -1                 // "area_id": 0,
    voucherId = -1              // "voucher_id",
    bikeId = -1                 // "bike_id": 0,
    mobileId = -1               // "mobile_id",
    sPrice = 0                  // "s_price",
    resultCount = -1            // "result_count": 500,
    amount = -1                 // "amount": 356.47499999999997

    get description (): string {

        let text = ""
        if (this.place.length > 0) {
            text += this.place + " "
        }

        return text + `S単価: ${this.sPrice.toFixed(2)}; 数量: ${ Math.round(this.resultCount).toLocaleString()}`
    }

    static fromJson (obj: any): PaymentAmount {
        const amount = new PaymentAmount()
        amount.id = obj.id
        if(obj.posting_date.length == 10){
            obj.posting_date =  obj.posting_date + " 00:00:00"
        }
        amount.postingDate = parse(obj.posting_date, datetimeFormat, new Date())
        amount.staffId = obj.staff_id
        amount.productId = obj.product_id
        amount.productName = obj.product_name
        amount.areaAddress = obj.area_address
        amount.place = obj.place
        amount.plannedCount = obj.planned_count
        amount.changedCount = obj.changed_count
        amount.adjustPrice = obj.adjust_price
        amount.areaId = obj.area_id
        amount.voucherId = obj.voucher_id
        amount.bikeId = obj.bike_id
        amount.mobileId = obj.mobile_id
        amount.sPrice = obj.s_price
        amount.resultCount = obj.result_count
        amount.amount = obj.amount

        return amount
    }

    static fromJsonArrToArr (objArr: Array<any>): PaymentAmount[] {
        const arr = [] as PaymentAmount[]
        objArr.forEach(obj => {
            arr.push(this.fromJson(obj))
        })
        return arr
    }

    toJsonObj(): any {
        return {
            id: this.id,
            posting_date: format(this.postingDate, datetimeFormat),
            staff_id: this.staffId,
            product_id: this.productId,
            product_name: this.productName,
            area_address: this.areaAddress,
            place: this.place,
            planned_count: this.plannedCount,
            changed_count: this.changedCount,
            adjust_price:   this.adjustPrice,
            area_id: this.areaId,
            voucher_id: this.voucherId,
            bike_id: this.bikeId,
            mobile_id: this.mobileId,
            s_price: this.sPrice,
            result_count: this.resultCount,
            amount: this.amount,
        }
    }

    get date(): Date {
        return this.postingDate
    }

    get vForKey (): string {
        return this.id.toString()
    }

    get dateString(): string {
        return format(this.postingDate, "M/d")
    }

    get stringForSearch(): string {
        return  `${this.dateString} ${this.productId} ${this.productName} ${this.areaAddress} ${this.place} ${this.resultCount} ${this.resultCount} ${this.amount}`
    }
}