
import {computed, defineComponent, nextTick, onMounted, ref} from "vue";
import {backendApi} from "@/utils/backend-api";
import {authStore} from "@/stores/auth-store";
import {authLevels} from "@/data-models/auth";
import {logoutMessages, subPaths} from "@/utils/consts";
import {useRouter} from "vue-router";
import {loadingOverlayStateStore} from "@/stores/loading-overlay-state-store";
import {FontSizes} from "@/utils/font-sizes";
import PasswordUpdateDialog from "@/components/PasswordUpdateDialog.vue";
import PasswordInput from "@/components/PasswordInput.vue";
import PasswordForgetDialog from "@/components/PasswordForgetDialog.vue";

const userIdNotFound = "user_id_not_found",
    passwordNotMatch = "password_not_match",
    invalidUserIdFormat = "invalid_user_id_format"

export default defineComponent({
    name: "Login",
    components: {PasswordForgetDialog, PasswordInput},
    setup() {
        const userId = ref<string>(""),
            password = ref<string>(""),
            warningMessage = ref<string>(""),
            showPWForgetDialog = ref<boolean>(false)

        const router = useRouter()

        // const fontSizeLarge1 = computed( {
        //     'font-size': FontSizes.large1
        // })

        const canClickLogin = (): boolean => {
            return userId.value.length > 0 && password.value.length > 0
        }

        const onLoginSuccess = async () => {

            warningMessage.value = ""

            switch (authStore.state.auth.authLevel) {
                case authLevels.DIST_STAFF:
                    await router.push({
                        path: subPaths.MY_PAGE,
                        query: {
                            staff_id: authStore.state.auth.userId,
                        }
                    })
                    break
                case authLevels.VIEWER:
                case authLevels.DIST_ADMIN:
                case authLevels.ACCOUNTING_ADMIN:
                case authLevels.ADMIN:
                case authLevels.BOARD_MEMBER:
                    await router.push(subPaths.HOME)
                    break
                default:
            }
        }

        const onClickLogin = async () => {
            loadingOverlayStateStore.commit("switchLoadingState", {
                show: true,
                message: "ログイン処理中…"
            })
            const resp = await backendApi.login(userId.value, password.value)
            console.log("onClickLogin", resp)

            if (resp.isSuccessful) {
                await onLoginSuccess()

            } else {

                authStore.commit("clearAuth")
                authStore.commit("clearToken")

                if (resp.value === userIdNotFound) {
                    warningMessage.value = "● 存在しない社員番号です。"
                    loadingOverlayStateStore.commit("fadeout")
                } else if (resp.value === passwordNotMatch) {
                    warningMessage.value = "● パスワードが正しくありません。"
                    loadingOverlayStateStore.commit("fadeout")
                }  else if (resp.value === invalidUserIdFormat) {
                    warningMessage.value = "● 社員番号の書式が正しくありません。"
                    loadingOverlayStateStore.commit("fadeout")
                } else {
                    await router.push({
                        path: subPaths.LOGGED_OUT,
                        query: {
                            logout_message: logoutMessages.LOGIN_FAILED.key
                        }
                    })
                }
            }
        }

        const onClickPWForgetLink = () => {
            showPWForgetDialog.value = true
        }

        const closePWForgetDialog = () => {
            showPWForgetDialog.value = false
        }

        onMounted(() => {
            loadingOverlayStateStore.commit("fadeout")
        })
        
        return {
            userId,
            password,
            canClickLogin,
            onClickLogin,
            FontSizes,
            warningMessage,
            onClickPWForgetLink,
            showPWForgetDialog,
            closePWForgetDialog,
        }
    }
})
