export const baseDomain = process.env.VUE_APP_BASE_URL
//export const baseDomain = "://payment-api-gen2.107.jp"

export const subPaths = {
    HOME: "/",
    LOGIN: "/login",
    LOGGED_OUT: "/logged_out",
    MY_PAGE: "/my_page",
    BRANCH_PAGE: "/branch_page",
    BRANCH_PAGE_APPROVAL_LIST: "/branch_page/approval_list",
    STAFF_LIST: "/staff_list",
    PAYMENT_AGGREGATION: "/payment_aggregation",
    POSTING_RECORDS_ALL_DIFF: "/posting_records_all_diff",
    POSTING_RECORDS_BRANCH_DIFF: "/posting_records_branch_diff",
    MY_PAGE_PREPAYMENT: "/my_page/prepayment",
    MY_PAGE_INVOICE_OF_MONTH: "/my_page/invoice_of_month",
    MY_PAGE_INVOICE_OF_MONTH_PDF: "/my_page/invoice_of_month/pdf",
    MY_PAGE_POSTING_DATA_DIFF: "/my_page/posting_data_diff",
    APPROVAL_LIST: "/approval_list",
    UPDATE_INVOICE_STATUS: "/update_invoice_status",
    MY_PAGE_PAYMENT_STOP: "/my_page/payment_stop",
    MY_PAGE_INVOICE_RESEND: "/my_page/invoice_resend",
}

export const passwordUpdateDialogMode = {
    SHOULD_UPDATE: "password_dialog_update_mode__should_update",
    NORMAL_UPDATE: "password_dialog_update_mode__normal_update",
}

export const invoiceStatuses = {
    INVOICE_FIXED: "invoice_fixed",
    APPROVAL_ACCEPT: "approval_accept"
}

export const logoutMessages = {
    NORMAL_LOGOUT: {
        key: "normal_logout",
        message: "正常にログアウトしました。"
    },
    LOGIN_FAILED: {
        key: "login_failed",
        message: "ログインできませんでした。"
    },
    TOKEN_EXPIRED: {
        key: "token_expired",
        message: "一定時間内の操作が必要です。"
    },
}

export const findLogoutMessageByKey = (key: string): string => {

    let msg = ""

    for (const logoutKey in logoutMessages) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const msgObj = logoutMessages[logoutKey]
        if (msgObj.key === key) {
            msg = msgObj.message
        }
    }
    return msg
}

export const datetimeFormat = "yyyy/MM/dd HH:mm:ss"

export const dateFormat = "yyyy/MM/dd"
