
import {computed, defineComponent, PropType, toRefs} from "vue";
import {types} from "sass";
import Null = types.Null;

export default defineComponent({
    name: "ToggleSwitch",
    props: {
        toggled: Boolean,
        toggledColor: {
            type: String,
            default: "dodgerblue"
        },
        width: {
            type: Number,
            default: 60,
        },
        beforeChange: {
            type: Function as PropType<(oldToggleState: boolean) => Promise<boolean>> | null,
            default: null
        }
    },
    emits: ["update:toggled"],
    setup(props, { emit }) {

        const { toggled } = toRefs(props)
        const computedToggled = computed<boolean>({
            get: () => toggled.value,
            set: val => {
                emit("update:toggled", val)
            }
        })

        const onClickSwitch = async () => {
            // console.log("onClickSwitch")

            let affirmed = true

            if (typeof props.beforeChange === "function") {
                affirmed = await props.beforeChange(props.toggled)
            }

            if (affirmed) {
                computedToggled.value = !props.toggled
            }
        }

        const frameStyle = (): any => {
            return {
                width: props.width + "px",
                height: props.width / 2 + "px",
                backgroundColor: computedToggled.value ? props.toggledColor : "gray",
                borderRadius: props.width / 4 + "px"
            }
        }

        const frameCoverStyle = (): any => {
            return {
                width: props.width + "px",
                height: props.width / 2 + "px",
                borderRadius: props.width / 4 + "px"
            }
        }

        const knobStyle = (): any => {

            const knobSize = props.width / 2 - 4

            return {
                width: knobSize + "px",
                height: knobSize + "px",
                backgroundColor: computedToggled.value ? props.toggledColor : "gray",
                borderRadius: knobSize / 2 + "px",
                left: (computedToggled.value ? 2 : props.width - knobSize - 2)  + "px"
            }
        }

        return {
            computedToggled,
            onClickSwitch,
            frameStyle,
            frameCoverStyle,
            knobStyle,
        }
    }
})
