import { parse, format, addDays } from "date-fns";
import {datetimeFormat} from "@/utils/consts";
import {PaymentAmount} from "@/data-models/payment-amount";
import {DateItem} from "@/data-models/daily-record";

export class PaymentHistory implements DateItem {
    id = -1         // "id",
    staffId = -1    // "staff_id",
    amount = 0      // "amount",
    createdAt = new Date()  // "created_at": "2022/02/09 07:48:05",
    paymentType = ""        // "payment_type": "",
    paid = false            // "paid": false
    systemFee = 0
  //  systemFeeTax = 0
    transferFee = 0
  //  transferFeeTax = 0

    static fromJson(obj: any): PaymentHistory {
        const history = new PaymentHistory()

        history.id = obj.id
        history.staffId = obj.staff_id
        history.amount = obj.amount
        history.systemFee = obj.system_fee
    //    history.systemFeeTax = obj.system_fee_tax
        history.transferFee = obj.transfer_fee
      //  history.transferFeeTax = obj.transfer_fee_tax
        history.createdAt = parse(obj.created_at, datetimeFormat, new Date())
        history.paymentType = obj.payment_type
        history.paid = obj.paid
        return history
    }

    static fromJsonArrToArr (objArr: Array<any>): PaymentHistory[] {
        const arr = [] as PaymentHistory[]
        objArr.forEach(obj => {
            arr.push(this.fromJson(obj))
        })
        return arr
    }

    toJsonObj(): any {

        return {
            id: this.id,
            staff_id: this.staffId,
            amount: this.amount,
            system_fee: this.systemFee,
    //        system_fee_tax: this.systemFeeTax,
            transfer_fee: this.transferFee,
    //        transfer_fee_tax: this.transferFeeTax,
            created_at: format(this.createdAt, datetimeFormat),
            payment_type: this.paymentType,
            paid: this.paid,
        }
    }

    // 開発用に報酬金額の半分程度の前払い履歴を生成する
    static generateDummyHistories(staffId: number): PaymentHistory[] {

        const histories = [] as PaymentHistory[]

        const hist1 = new PaymentHistory()
        hist1.id = 1000
        hist1.staffId = staffId
        hist1.amount = 15000
        hist1.createdAt = addDays(new Date(), -20)
        hist1.paymentType = "advance"
        hist1.paid = true
        histories.push(hist1)

        const hist2 = new PaymentHistory()
        hist2.id = 1100
        hist2.staffId = staffId
        hist2.amount = 3000
        hist2.createdAt = addDays(new Date(), -25)
        hist2.paymentType = "advance"
        hist2.paid = true
        histories.push(hist2)

        return histories
    }

    get date(): Date {
        return this.createdAt
    }
}