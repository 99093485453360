import {ApiResponse} from "@/data-models/api-response";
import {authStore} from "@/stores/auth-store";
import {Auth} from "@/data-models/auth";
import {startOfMonth, addMonths, format, addDays} from "date-fns";
import {baseDomain, dateFormat, datetimeFormat, subPaths} from "@/utils/consts";
import {calcEndDate, calcStartDate} from "@/utils/date-utils";

export const apiPaths = {
    LOGIN: "/admin_login",
    VERIFY_TOKEN: "/verify_token",
    BRANCH_LIST: "/branch_list",
    STAFF_LIST: "/staff_list",
    PAYMENT_AMOUNT_BY_MONTH: "/payment_amount_by_month",
    PAYMENT_AMOUNT_BY_MONTH_PRODUCT_NAME: "/payment_amount_by_month_product_name",
    PAYMENT_HISTORY_BY_MONTH: "/payment_history_by_month",
    APPROVAL_CREATE: "/approval/create",
    APPROVAL_UPDATE: "/approval/update",
    APPROVAL_LIST: "/approval_list",
    INVOICE_PDF: "/invoice_pdf",
    ALL_INVOICE_PDF: "/all_invoice_pdf",
    BRANCH_INVOICE_PDF: "/branch_invoice_pdf",
    APPROVAL_LIST_BY_BRANCH: "/approval_list_by_branch",
    APPROVAL_BY_STAFF_ID: "/approval_by_staff_id",
    PAYMENT_REQUEST: "/payment/request",
    PAYMENT_CANCEL: "/payment/cancel",
    ADVANCE_TRANSFER_CSV: "/advance_transfer_csv",
    MONTHLY_TRANSFER_TXT: "/monthly_transfer_txt",
    MONTHLY_TRANSFER_EXCEL: "/monthly_transfer_excel",
    STAFF_BY_ID: "/staff_by_id",
    PAYMENT_AGGREGATION: "/payment_aggregation",
    MONTHLY_BANK_TRANSFER_FEE: "/monthly_bank_transfer_fee",
    PASSWORD_UPDATE: "/password/update",
    PASSWORD_RESET: "/password/reset",
    SITE_STATUS: "/site_status",
    UPDATE_SITE_STATUS: "/site_status",
    MAIL_REGISTER: "/mail/register",
    VERIFY_CERTIFICATE: "/verify_certificate",
    PREPARE_ALL_DRAFT_PDF: "/prepare_all_draft_pdf",
    PREPARE_ALL_DRAFT_PDF_PROCESS: "/prepare_all_draft_pdf_process",
    TAKE_SNAP_SHOT_OF_POSTING_RECORDS: "/take_snap_shot",
    POSTING_RECORDS_DIFF: "/posting_records_diff",
    POSTING_RECORDS_ALL_DIFF: "/posting_records_all_diff2",
    POSTING_RECORDS_BRANCH_DIFF: "/posting_records_branch_diff",
    NEGATIVE_AMOUNT_LIST: "/negative_amount_list",
    APPROVAL_BY_BRANCH: "/approval/by_branch",
    PAYMENT_AMOUNT_BY_MONTH_PRODUCT_MAME_AS_EXCEL: "/payment_amount_by_month_product_name_as_excel",
    APPROVAL_BY_BRANCH_AFFECT_LIST: "/approval/by_branch_affect_list",
    PAYMENT_STOP: "/payment/stop",
    PAYMENT_STOP_STATUS: "/payment/stop_status",
    INVOICE_RESEND: "/invoice/resend",
    INVOICE_STATUS: "/invoice/status",
}

export const backendApi = (() => {

    const apiUrl = "https" + baseDomain + "/api/v1"

    const inquireCore = async (subPath: string,
                               token = "",
                               method = "",
                               body: any|null = null,
                               isLoginRequest = false): Promise<ApiResponse> => {

        const init = {
            method: method,
            headers: {
                "Content-Type": "application/json",
                // "Access-Control-Allow-Origin": "*",
                Authorization: token
            },
            body: ""
        }

        if (body) {
            init.body = JSON.stringify(body)
        }


        const resp = await fetch(apiUrl + subPath, init)
        // console.log("const resp = await fetch", resp)
        const json = await resp.json()
        // console.log(json)
        const apiResp = ApiResponse.fromJson(json)
        apiResp.status = resp.status

        console.log(apiResp)

        const token2 = resp.headers.get("Authorization") as string
        // console.log(token2)

        // リクエストのレスポンスが返ってきた時点で、すでにログアウトしていて、トークンがクリアされていることがある。
        // かつそれはログインリクエストではない
        if (!authStore.getters.existsToken && !isLoginRequest) {
            // ステータスコードをNot Authorizedに書き換える
            apiResp.status = 401
            // 返ってきたトークンで更新しない。

        } else {
            if (token2 && token2.length > 30) {
                authStore.commit("updateToken", {token: token2})

            } else {
                authStore.commit("clearToken")
            }
        }

        return  apiResp
    }

    const verifyToken = async (token: string): Promise<boolean> => {

        const apiResp = await inquireCore(apiPaths.VERIFY_TOKEN, token,"POST")
        // トークンの検証が成功するとストアに新しいトークンが格納されている。
        if (authStore.getters.existsToken) {
            const auth = Auth.fromJson(apiResp.value)
            console.log(auth)

            // 20220423 トークン検証ではCERTを返さないのでそれ以外を読み込む。暫定的な意味合い
            authStore.commit("updateAuthExceptCert", {auth})
            return true
        }
        return false
    }

    const login = async (userId: string, password: string): Promise<ApiResponse> => {
        const apiResp = await inquireCore(apiPaths.LOGIN, "", "POST",{
            user_id: Number(userId.trim()),
            password: password.trim(),
        }, true)

        console.log(apiResp)

        if (authStore.getters.existsToken) {
            const auth = Auth.fromJson(apiResp.value)
            authStore.commit("updateAuth", {auth})
        }
        return apiResp
    }

    const inquireBranchList = async (): Promise<ApiResponse> => {
        return  await inquireCore(apiPaths.BRANCH_LIST, authStore.state.token,"POST",null)
    }

    const inquireStaffList = async (branchName: string, accountActiveFlag: number): Promise<ApiResponse> => {

        const body = {
            branch: branchName,
            account_active_flag: accountActiveFlag
        }
        // console.log(body)

        return await inquireCore(apiPaths.STAFF_LIST, authStore.state.token,"POST", body)
    }

    const inquirePaymentAmounts = async (staffId: number, backwardMonthCount: number): Promise<ApiResponse> => {
        const body = {
                    staff_id: staffId,
                    month: backwardMonthCount,
                }

                return await inquireCore(apiPaths.PAYMENT_AMOUNT_BY_MONTH, authStore.state.token,"POST", body)
    }

    const inquirePaymentAmountsWithProductName = async (staffId: number, backwardMonthCount: number): Promise<ApiResponse> => {
        const body = {
            staff_id: staffId,
            month: backwardMonthCount,
        }

        return await inquireCore(apiPaths.PAYMENT_AMOUNT_BY_MONTH_PRODUCT_NAME, authStore.state.token,"POST", body)
    }

    const inquirePaymentHistories = async (staffId: number, backwardMonthCount: number): Promise<ApiResponse> => {
        const body = {
            staff_id: staffId,
            month: backwardMonthCount,
        }

        return await inquireCore(apiPaths.PAYMENT_HISTORY_BY_MONTH, authStore.state.token,"POST", body)
    }

    const inquireInvoiceMessageByStaffId = async (staffId: number, backwardMonthCount = 0): Promise<ApiResponse> => {
        const body = {
            staff_id: staffId,
            month: backwardMonthCount,
        }

        return await inquireCore(apiPaths.APPROVAL_BY_STAFF_ID, authStore.state.token,"POST", body)
    }

    const inquireForCreateApproval = async (staffId: number, message: string, accept: boolean): Promise<ApiResponse> => {
        const body = {
            staff_id: staffId,
            message: message,
            accept: accept,
        }

        return await inquireCore(apiPaths.APPROVAL_CREATE, authStore.state.token,"POST", body)
    }

    const inquireForUpdateApproval = async (staffId:number, approvalId: number, message: string, accept: boolean): Promise<ApiResponse> => {
        const body = {
            staff_id: staffId,
            id: approvalId,
            message: message,
            accept: accept,
        }

        return await inquireCore(apiPaths.APPROVAL_UPDATE, authStore.state.token,"POST", body)
    }

    const inquireInvoicePDF = async (staffId:number, backwardMonthCount = 0): Promise<ApiResponse> => {
        const body = {
            staff_id: staffId,
            month: backwardMonthCount
        }

        return await inquireCore(apiPaths.INVOICE_PDF, authStore.state.token,"POST", body)
    }

    const inquireApprovalList = async (backwardMonthCount = 0): Promise<ApiResponse> => {
        const body = {
            month: backwardMonthCount
        }

        console.log(body)

        return await inquireCore(apiPaths.APPROVAL_LIST, authStore.state.token,"POST", body)
    }

    const inquireApprovalListForBranch = async (branchName: string, backwardMonthCount = 0): Promise<ApiResponse> => {
        const body = {
            branch: branchName,
            month: backwardMonthCount
        }

        return await inquireCore(apiPaths.APPROVAL_LIST_BY_BRANCH, authStore.state.token,"POST", body)
    }

    // const inquirePaymentAmountsForCurrentPeriod = async (staffId: number): Promise<ApiResponse> => {
    //
    //     const endDate = calcEndDate()
    //     // 今月の始まり日を取得
    //     const startDate = calcStartDate(endDate)
    //
    //     const body = {
    //         staff_id: staffId,
    //         from_datetime: format(startDate, datetimeFormat),
    //         to_datetime: format(endDate, datetimeFormat)
    //     }
    //
    //     return await inquireCore(apiPaths.PAYMENT_AMOUNT, authStore.state.token, body)
    // }
    //
    // const inquirePaymentHistoriesForCurrentPeriod = async (staffId: number): Promise<ApiResponse> => {
    //     const endDate = calcEndDate()
    //     // 今月の始まり日を取得
    //     const startDate = calcStartDate(endDate)
    //
    //     const body = {
    //         staff_id: staffId,
    //         from_datetime: format(startDate, datetimeFormat),
    //         to_datetime: format(endDate, datetimeFormat)
    //     }
    //
    //     // console.log(body)
    //
    //     return await inquireCore(apiPaths.PAYMENT_HISTORY, authStore.state.token, body)
    // }

    const makePrepaymentRequest = async (staffId: number, amount: number) => {

        const body = {
            staff_id: staffId,
            amount: amount,
            payment_type: "advance"
        }

        // console.log(body)

        return await inquireCore(apiPaths.PAYMENT_REQUEST, authStore.state.token,"POST", body)
    }

    const loadStaffById = async (staffId: number) => {
        const body = {
            staff_id: staffId,
        }

        // console.log(body)

        return await inquireCore(apiPaths.STAFF_BY_ID, authStore.state.token,"POST", body)
    }
    
    const inquirePaymentAggregations = async (): Promise<ApiResponse> => {
        const endDate = calcEndDate()
        // 今月の始まり日を取得
        const startDate = calcStartDate(endDate)

        console.log(endDate)

        const body = {
            from_datetime: format(startDate, datetimeFormat),
            to_datetime: format(endDate, datetimeFormat)
        }

        console.log(body)

        return await inquireCore(apiPaths.PAYMENT_AGGREGATION, authStore.state.token,"POST", body)
    }
    
    const inquirePrepaymentFile = async (date: Date): Promise<ApiResponse> => {
        const body = {
            on_date: format(date, datetimeFormat)
        }
        console.log(authStore.state.token)
        return await inquireCore(apiPaths.ADVANCE_TRANSFER_CSV, authStore.state.token,"POST", body)
    }

    const inquireMonthlyPaymentTXT = async (date: Date): Promise<ApiResponse> => {
        const body = {
            on_month: format(date, datetimeFormat)
        }
        return await inquireCore(apiPaths.MONTHLY_TRANSFER_TXT, authStore.state.token,"POST", body)
    }

    const inquireMonthlyPaymentExcel = async (date: Date): Promise<ApiResponse> => {
        const body = {
            on_month: format(date, datetimeFormat)
        }
        return await inquireCore(apiPaths.MONTHLY_TRANSFER_EXCEL, authStore.state.token,"POST", body)
    }
    
    const inquireAllInvoicePDFZip = async (): Promise<ApiResponse> => {
        return await inquireCore(apiPaths.ALL_INVOICE_PDF, authStore.state.token)
    }

    const inquireBranchInvoicePDFZip = async (branchName: string): Promise<ApiResponse> => {
        const body = {
            branch: branchName
        }
        return await inquireCore(apiPaths.BRANCH_INVOICE_PDF, authStore.state.token,"POST", body)
    }

    const inquireBankTransferFeeForMonthlyPay = async (staffId: number, amount: number): Promise<ApiResponse> => {
        const body = {
            staff_id: staffId,
            amount: amount
        }
        return await inquireCore(apiPaths.MONTHLY_BANK_TRANSFER_FEE, authStore.state.token,"POST", body)
    }
    
    const inquireUpdatePassword = async (userId: string, password: string): Promise<ApiResponse> => {
        const body = {
            user_id: userId,
            password: password
        }
        return await inquireCore(apiPaths.PASSWORD_UPDATE, authStore.state.token,"POST", body)
    }

    const inquireResetPassword = async (userId: string): Promise<ApiResponse> => {
        const body = {
            user_id: userId,
        }
        return await inquireCore(apiPaths.PASSWORD_RESET, authStore.state.token,"POST", body)
    }

    const inquireSiteStatus = async (): Promise<ApiResponse> => {

        return await inquireCore(apiPaths.SITE_STATUS, authStore.state.token,"POST",null)
    }

    const inquireUpdateSiteStatus = async (status: string): Promise<ApiResponse> => {
        const body = {
            site_status: status
        }
        return await inquireCore(apiPaths.UPDATE_SITE_STATUS, authStore.state.token,"PUT", body)
    }

    const sendVerificationMail = async (userId: string, mail: string): Promise<ApiResponse> => {
        const body = {
            user_id: userId,
            mail,
        }
        return await inquireCore(apiPaths.MAIL_REGISTER, authStore.state.token,"POST", body)
    }
    
    const inquireCertStatus = async () => {
        return await inquireCore(apiPaths.VERIFY_CERTIFICATE, authStore.state.token,"POST",)
    }

    const requestPrepareAllDraftPDF = async () => {
        return await inquireCore(apiPaths.PREPARE_ALL_DRAFT_PDF, authStore.state.token,"POST",)
    }

    const inquireAllDraftPDFPreparationProcess = async (): Promise<ApiResponse> => {
        return await inquireCore(apiPaths.PREPARE_ALL_DRAFT_PDF_PROCESS, authStore.state.token,"POST",)
    }

    const inquirePostingRecordsDiff = async (staffId: number): Promise<ApiResponse> => {
        const body = {
            staff_id: staffId
        }
        return await inquireCore(apiPaths.POSTING_RECORDS_DIFF, authStore.state.token,"POST", body)
    }

    const inquirePostingRecordsAllDiff = async (): Promise<ApiResponse> => {
        return await inquireCore(apiPaths.POSTING_RECORDS_ALL_DIFF, authStore.state.token,"POST",)
    }

    const inquirePostingRecordsBranchDiff = async (branch: string): Promise<ApiResponse> => {
        const body = {
            branch
        }
        return await inquireCore(apiPaths.POSTING_RECORDS_BRANCH_DIFF, authStore.state.token,"POST", body)
    }

    const inquireTakePostingRecordSnapshot = async (): Promise<ApiResponse> => {
        return await inquireCore(apiPaths.TAKE_SNAP_SHOT_OF_POSTING_RECORDS, authStore.state.token,"POST",)
    }

    const inquireNegativeAmountList = async (): Promise<ApiResponse> => {
        return await inquireCore(apiPaths.NEGATIVE_AMOUNT_LIST, authStore.state.token,"POST",)
    }

    const inquireApproveByBranch = async (branch: string): Promise<ApiResponse> => {
        const body = {
            branch
        }
        return await inquireCore(apiPaths.APPROVAL_BY_BRANCH, authStore.state.token,"POST", body)
    }

    const inquireAffectedListByBranchConfirm = async (branch: string): Promise<ApiResponse> => {
        const body = {
            branch
        }
        return await inquireCore(apiPaths.APPROVAL_BY_BRANCH_AFFECT_LIST, authStore.state.token,"POST", body)
    }

    const inquireMonthlyInvoiceDetailExcel = async (staffId: number, backwardMonthCount: number): Promise<ApiResponse> => {
        const body = {
            staff_id: staffId,
            month: backwardMonthCount,
        }
        return await inquireCore(apiPaths.PAYMENT_AMOUNT_BY_MONTH_PRODUCT_MAME_AS_EXCEL, authStore.state.token,"POST", body)
    }

    const inquirePaymentStopStatus = async (userId: number): Promise<ApiResponse> => {
        const body = {
            user_id: userId,
        }
        return await inquireCore(apiPaths.PAYMENT_STOP_STATUS, authStore.state.token,"POST", body)
    }

    const requestPaymentStop = async (userId: number, stopType: string): Promise<ApiResponse> => {
        const body = {
            user_id: userId,
            stop_type: stopType,
        }
        return await inquireCore(apiPaths.PAYMENT_STOP, authStore.state.token,"POST", body)
    }

    const inquireInvoiceStatus = async (userId: number): Promise<ApiResponse> => {
        const body = {
            user_id: userId,
        }
        return await inquireCore(apiPaths.INVOICE_STATUS, authStore.state.token,"POST", body)
    }

    const requestResendInvoice = async (userId: number, month: string): Promise<ApiResponse> => {
        const body = {
            user_id: userId,
            month,
        }
        return await inquireCore(apiPaths.INVOICE_RESEND, authStore.state.token,"POST", body)
    }

    return {
        verifyToken,
        login,
        inquireBranchList,
        inquireStaffList,
        makePrepaymentRequest,
        loadStaffById,
        inquirePaymentAggregations,
        inquirePrepaymentFile,
        inquireMonthlyPaymentTXT,
        inquireMonthlyPaymentExcel,
        inquirePaymentAmounts,
        inquirePaymentAmountsWithProductName,
        inquirePaymentHistories,
        inquireBankTransferFeeForMonthlyPay,
        inquireInvoiceMessageByStaffId,
        inquireForCreateApproval,
        inquireForUpdateApproval,
        inquireInvoicePDF,
        inquireApprovalList,
        inquireApprovalListForBranch,
        inquireAllInvoicePDFZip,
        inquireBranchInvoicePDFZip,
        inquireUpdatePassword,
        inquireResetPassword,
        inquireSiteStatus,
        inquireUpdateSiteStatus,
        sendVerificationMail,
        inquireCertStatus,
        requestPrepareAllDraftPDF,
        inquireAllDraftPDFPreparationProcess,
        inquirePostingRecordsDiff,
        inquireTakePostingRecordSnapshot,
        inquirePostingRecordsAllDiff,
        inquirePostingRecordsBranchDiff,
        inquireNegativeAmountList,
        inquireApproveByBranch,
        inquireMonthlyInvoiceDetailExcel,
        inquireAffectedListByBranchConfirm,
        inquirePaymentStopStatus,
        requestPaymentStop,
        inquireInvoiceStatus,
        requestResendInvoice,
    }
})()
