
import {computed, defineComponent, ref, toRefs, watch} from "vue";

export default defineComponent({
    name: "PasswordInput",
    props: {
        password: String,
    },
    emits:["update:password"],
    setup(props, { emit }) {

        const showPassword = ref<boolean>(false)
        const { password } = toRefs(props)

        const passwordComputed = computed({
            get: () => password.value,
            set: val => {
                emit("update:password", val)
            }
        })

        const updateShowPassword = (show: boolean) => {
            showPassword.value = show
        }

        const inputType = (): string => {
            return showPassword.value ? "text" : "password"
        }

        return {
            showPassword,
            updateShowPassword,
            inputType,
            passwordComputed,
        }
    }
})
