import {parse} from "date-fns";
import {datetimeFormat} from "@/utils/consts";

export class InvoiceProcessStatus {
    status = ""
    processRatio = 0
    createdAt = new Date()

    static fromJson(obj: any): InvoiceProcessStatus {
        const ips = new InvoiceProcessStatus()
        ips.status = obj.status

        if (obj.process_ratio) {
            ips.processRatio = obj.process_ratio
        }

        if (obj.created_at) {
            ips.createdAt = parse(obj.created_at, datetimeFormat, new Date())
        }

        return ips
    }
}

export const invoiceProcessStatuses = {
    NOT_AVAILABLE: "not_available",
    AVAILABLE: "available",
    ON_PROCESS: "on_process",
}