import {parse} from "date-fns";
import {datetimeFormat} from "@/utils/consts";
import {v4 as uuidv4} from "uuid";

export class PostingRecordAllDiffSummary {

    entities = [] as BranchDiffEntity[]

    updatedAt = new Date()

    static fromJson(obj: any): PostingRecordAllDiffSummary {
        const summ = new PostingRecordAllDiffSummary()

        obj.entities.forEach((entObj: any) => {
            const bEnt = BranchDiffEntity.fromJson(entObj)
            bEnt.vForKey = `branch_${bEnt.branch}_${uuidv4()}`
            summ.entities.push(bEnt)
        })

        summ.updatedAt = parse(obj.updated_at, datetimeFormat, new Date())

        return summ
    }

}

export class BranchDiffEntity {
    branch = ""
    added = 0
    changed = 0
    deleted = 0
    vForKey = ""

    static fromJson(obj: any): BranchDiffEntity {
        const b = new BranchDiffEntity()
        b.branch = obj.branch
        b.added = obj.added
        b.changed = obj.changed
        b.deleted = obj.deleted
        return b
    }

    get hasDiff (): boolean {
        console.log("hasDiff")
        return this.added + this.changed + this.deleted > 0
    }
}


// export class DiffSummary {
//     name = ""
//     added = 0
//     changed = 0
//     deleted = 0
//     vForKey = ""
//
//     get hasDiff (): boolean {
//         console.log("hasDiff")
//         return this.added + this.changed + this.deleted > 0
//     }
// }