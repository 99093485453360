
import {defineComponent, PropType} from "vue";
import {RouteLocationRaw, useRouter} from "vue-router";
import {loadingOverlayStateStore} from "@/stores/loading-overlay-state-store";

export type ancestryType = {
    name: string,
    pathObj: RouteLocationRaw
}

export default defineComponent({
    name: "Breadcrumbs",
    props: {
        here: {
            type: String,
            required: true,
        },
        ancestries: {
            type: Array as PropType<Array<ancestryType>>,
            default: [] as ancestryType[]
        }
    },
    setup(props) {
        const router = useRouter()

        const onClickLink = (pathObj: RouteLocationRaw) => {
            loadingOverlayStateStore.commit("switchLoadingState", {
                show: true,
                message: "移動中…"
            })
            router.push(pathObj)
        }

        return {
            onClickLink,
        }
    }
})
