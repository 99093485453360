import {baseDomain} from "@/utils/consts";
import {authStore} from "@/stores/auth-store";

export type downloadRatioCallbackType = (msg: string, dlRatio: number) => void
export type invoiceZipDownloaderErrorFuncType = (msg: string) => void

export const zipDownloadState = {
    OUT_OF_PROCESS: "zipDownloadState__out_of_process",
    DOWNLOADING: "zipDownloadState__downloading",
    SAVING_FILE: "zipDownloadState__saving_file",
    FILE_NOT_FOUND: "zipDownloadState__file_not_found",
}

const fileNotFoundMessage = "file_not_found"

export class InvoiceZipDownloader {

    private static wsUrl = "wss" + baseDomain + ":9001"

    onError: invoiceZipDownloaderErrorFuncType = (msg: string) => {
        //
    }

    private isCanceled = false

    start (cb: downloadRatioCallbackType, branch = ""): Promise<void> {

        let fileName = "",
            fileSize = 0,
            buf = new Uint8Array(0),
            isDownloading = false

        // ダウンロードしている限り、レシオを返す。
        const timerId = window.setInterval(() => {
            if (isDownloading) {
                const dlRatio = fileSize === 0 ? 0 : buf.length / fileSize
                cb(zipDownloadState.DOWNLOADING, dlRatio)
            } else {
                window.clearInterval(timerId)
            }
        }, 1000)

        let branchName = "一括データ"

        return new Promise(resolve => {
            const ws = new WebSocket(InvoiceZipDownloader.wsUrl)
            ws.onopen = () => {
                console.log("InvoiceZipDownloader::ws.onopen")

                let data: any

                // 支店名が入ってくるか否か
                if (branch.length > 0) {
                    branchName = branch
                    data = {
                        token: authStore.state.token,
                        branch
                    }
                } else {
                    data = {
                        token: authStore.state.token,
                    }
                }

                ws.send(JSON.stringify(data))
            }

            ws.onerror = () => {
                console.warn("InvoiceZipDownloader::ws.onerror")
            }

            ws.onmessage = async ev => {

                if (this.isCanceled) {
                    isDownloading = false
                    resolve()
                    return
                }

                // バイナリデータはJSONパースできないのでtry-catch節で囲む
                try {
                    const obj = JSON.parse(ev.data)

                    console.log(obj)

                    fileName = obj.file_name
                    fileSize = obj.file_size

                    // トークン検証に失敗するとファイル名が空文字かつファイルサイズが0で返ってくる
                    if (fileName.length === 0 && fileSize === 0) {

                        if (obj.message === fileNotFoundMessage) {
                            // スタッフがいないので請求書が存在しない
                            console.log(`${branchName}: ${obj.message}`)
                            isDownloading = false
                            cb(zipDownloadState.FILE_NOT_FOUND, 0)
                        } else {
                            // トークン検証に失敗
                            this.onError(obj.message)
                        }

                        ws.close()
                        resolve()
                    } else {
                        // トークン検証に成功、有効なファイルが存在する。
                        isDownloading = true
                    }
                } catch (e) {
                    const blob = ev.data as Blob
                    const buf2 = await blob.arrayBuffer()

                    const tmpBuf = buf

                    buf = new Uint8Array(tmpBuf.length + blob.size)
                    buf.set(tmpBuf)
                    buf.set(new Uint8Array(buf2), tmpBuf.length)

                    // console.log(buf)

                    if (buf.length >= fileSize) {
                        cb(zipDownloadState.SAVING_FILE, 1)
                        isDownloading = false
                        ws.close()

                        const fileBlob = new Blob([buf.buffer], {type: "application/octet-binary"}),
                            blobUrl = URL.createObjectURL(fileBlob)

                        const link = document.createElement("a")
                        link.href = blobUrl
                        link.download = fileName
                        link.type = "application/zip"
                        link.click()

                        resolve()
                    }
                }
            }
        })
    }

    stop (): void {
        this.isCanceled = true
        console.log("InvoiceZipDownloader::stopped!")
    }

}