import {parse} from "date-fns";
import {datetimeFormat} from "@/utils/consts";
import {v4 as uuidv4} from "uuid";

export class PostingRecordBranchDiffSummary {

    entities = [] as StaffDiffEntity[]

    updatedAt = new Date()

    static fromJson(obj: any): PostingRecordBranchDiffSummary {
        const summ = new PostingRecordBranchDiffSummary()

        obj.entities.forEach((entObj: any) => {
            const staffDiff = StaffDiffEntity.fromJson(entObj)
            staffDiff.vForKey = `staff_diff_${staffDiff.staffId}_${uuidv4()}`
            summ.entities.push(staffDiff)
        })

        summ.updatedAt = parse(obj.updated_at, datetimeFormat, new Date())

        return summ
    }

}

export class StaffDiffEntity {
    staffId = 0
    name = ""
    added = 0
    changed = 0
    deleted = 0
    vForKey = ""

    static fromJson(obj: any): StaffDiffEntity {
        const b = new StaffDiffEntity()
        b.staffId = obj.staff_id
        b.added = obj.added
        b.changed = obj.changed
        b.deleted = obj.deleted
        return b
    }

    get hasDiff (): boolean {
        console.log("hasDiff")
        return this.added + this.changed + this.deleted > 0
    }
}


