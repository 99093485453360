
import {defineComponent, PropType, ref, watch} from "vue";
import DialogFrame from "@/components/DialogFrame.vue";
import {amountToString} from "@/utils/string-utils";
import {backendApi} from "@/utils/backend-api";
import {loadingOverlayStateStore} from "@/stores/loading-overlay-state-store";
import {FontSizes} from "@/utils/font-sizes";
import {Staff} from "@/data-models/staff";
import {logoutMessages, subPaths} from "@/utils/consts";
import {useRouter} from "vue-router";

export default defineComponent({
    name: "BranchApprovalAffectedListDialog",
    components: {
        DialogFrame,
    },
    props: {
        show: {
            type: Boolean,
            required: true,
        },
        close: {
            type: Function as PropType<() => void>,
            required: true
        },
        branchName: {
            type: String,
            required: true,
        },
        onFinishApproval: {
            type: Function as PropType<() => void>,
            required: true,
        }
    },
    setup(props) {

        const affectedList = ref<Staff[]>([] as Staff[])

        const router = useRouter()

        const update = async () => {
            const resp = await backendApi.inquireAffectedListByBranchConfirm(props.branchName)

            if (resp.isAuthorized) {
                affectedList.value = Staff.fromJsonArrToArr(resp.value)
                console.log(affectedList.value)
            } else {
                await router.push({
                    path: subPaths.LOGGED_OUT,
                    query: {
                        logout_message: logoutMessages.TOKEN_EXPIRED.key
                    }
                })
            }
        }

        watch(() => props.show, async () => {
            if (props.show) {
                await update()
            }
        })

        const onClickApproveByBranch = async () => {
            const resp = await backendApi.inquireApproveByBranch(props.branchName)
            if (resp.isAuthorized) {
                props.onFinishApproval()
            } else {
                await router.push({
                    path: subPaths.LOGGED_OUT,
                    query: {
                        logout_message: logoutMessages.TOKEN_EXPIRED.key
                    }
                })
            }
        }

        const canClickApprovalByBranch = (): boolean => {
            return affectedList.value.length > 0
        }

        return {
            affectedList,
            onClickApproveByBranch,
            canClickApprovalByBranch,
            FontSizes,
        }
    }
})
