
import {computed, defineComponent, PropType, toRefs} from "vue";

export default defineComponent({
    name: "ClearableInput",
    props: {
        text: {
            type: String,
            default: ""
        },
    },
    emits:[ "update:text", ],
    setup(props, { emit }) {

        const { text } = toRefs(props)
        const textInner = computed({
            get: (): string => {
                return text.value
            },
            set: (val: string) => {
                emit("update:text", val)
            }
        })

        const onClickClear = () => {
            textInner.value = ""
        }

        return {
            textInner,
            onClickClear,
        }
    }
})
