

// TODO: 全銀ファイルは役員のみダウンロード可能にする

import {defineComponent, nextTick, onMounted, ref, watch} from "vue";
import {backendApi} from "@/utils/backend-api";
import {BranchOffice} from "@/data-models/branch-office";
import {useRouter} from "vue-router";
import {invoiceStatuses, logoutMessages, subPaths} from "@/utils/consts";
import {authLevels} from "@/data-models/auth";
import {authStore} from "@/stores/auth-store";
import {ApiResponse} from "@/data-models/api-response";
import {loadingOverlayStateStore} from "@/stores/loading-overlay-state-store";
import { FontSizes } from "@/utils/font-sizes";
import {
    downloadCSV,
    downloadExcelFile,
    downloadShiftJISTXT,
    downloadUTF8TXT,
    downloadZIPFile
} from "@/utils/file-utils";
import ToggleSwitch from "@/components/ToggleSwitch.vue";
import DraftInvoiceDownloader from "@/components/DraftInvoiceDownloader.vue";
import {confirmDialogStateStore} from "@/stores/confirm-dialog-state-store";
import {toastStore} from "@/stores/toast-store";

const haifuAppBaseUrl = "https://aichi.107.jp"

export default defineComponent({
    name: "Home",
    components: {DraftInvoiceDownloader, ToggleSwitch},
    setup(props) {

        const branches = ref<BranchOffice[]>([] as BranchOffice[]),
                acceptApproval = ref<boolean>(false),
                invoiceFixed = ref<boolean>(false),
                approvalAcceptText = ref<string>(""),
                invoiceFixedText = ref<string>("")

        const router = useRouter()

        let shouldWatchStatusChange = true
                // isFirstToggleChange = false // 初回読み込み時にサイトステータスチェックが走らないようにする

        /**
         * 請求承認問い合わせを解放する時の確認ダイアログ
         * acceptApproval.value === true
         * で呼ばれる
         */
        const showConfirmForStartAcceptApproval = (): Promise<boolean> => {

            return new Promise<boolean>(resolve => {
                confirmDialogStateStore.commit("switchConfirmDialog", {
                    show: true,
                    title: "承認問い合わせの開始",
                    message: "次回、承認問い合わせを締め切り、請求内容を確定するとき一斉メールが送信されます。問い合わせ受付を開始しますか？",
                    affirmativeText: "開始",
                    affirmCallback: async (affirmed: boolean) => {
                        resolve(affirmed)
                    },
                })
            })

        }

        /**
         * 請求内容を確定するときの確認ダイアログ
         * acceptApproval.value === false
         * で呼ばれる
         */
        const showConfirmForFixInvoice = (): Promise<boolean> => {

            return new Promise<boolean>(resolve => {
                confirmDialogStateStore.commit("switchConfirmDialog", {
                    show: true,
                    title: "請求内容の確定",
                    message: "請求内容を確定すると、一斉メールが送信されます。確定しますか？",
                    affirmativeText: "確定",
                    affirmCallback: async (affirmed: boolean) => {
                        resolve(affirmed)
                    },
                })
            })

        }

        const beforeAcceptApprovalStateChange = (oldState: boolean): Promise<boolean> => {

            if (oldState) {
                // 承認問い合わせ受け入れ -> 締め切り
                // の変化の前に呼ばれる。
                // 「請求確定してよいかどうか」を確認する。
                // Promiseを返す。
                return showConfirmForFixInvoice()

            } else {
                // 承認問い合わせ締め切り -> 受け入れ
                // の変化の前に呼ばれる。
                // 「承認問い合わせを開始してよいかどうか」を確認する。
                // Promiseを返す。
                return showConfirmForStartAcceptApproval()
            }
        }

        watch(() => acceptApproval.value, async () => {
            // console.log("approvalToggled.value: " + approvalToggled.value)

            if (!shouldWatchStatusChange) {
                return
            }

            shouldWatchStatusChange = false
            invoiceFixed.value = !acceptApproval.value
            await inquireUpdateSiteStatus()
            shouldWatchStatusChange = true
        })

        const beforeInvoiceFixedStateChange = (oldState: boolean): Promise<boolean> => {

            if (oldState) {
                // 請求確定 -> 確定解除
                // の変化の前に呼ばれる。
                // 「承認問い合わせ受け入れを開始するかどうか」を確認
                // Promiseを返す。
                return showConfirmForStartAcceptApproval()

            } else {
                // 請求未確定 -> 請求確定
                // の変化の前に呼ばれる。
                // 「請求確定するかどうか」を確認
                // Promiseを返す。
                return showConfirmForFixInvoice()
            }
        }

        watch(() => invoiceFixed.value, async () => {

            if (!shouldWatchStatusChange) {
                return
            }

            shouldWatchStatusChange = false
            acceptApproval.value = !invoiceFixed.value
            await inquireUpdateSiteStatus()
            shouldWatchStatusChange = true
        })

        const inquireUpdateSiteStatus = async () => {
            const status = acceptApproval.value
                    ? invoiceStatuses.APPROVAL_ACCEPT
                    : invoiceStatuses.INVOICE_FIXED

            const statusResp = await backendApi.inquireUpdateSiteStatus(status)
            if (statusResp.isAuthorized) {
                updateInvoiceApprovalStatusUIs(statusResp)
            } else {
                await onTokenExpired(statusResp)
            }
        }

        const updateApprovalAcceptText = () => {
            approvalAcceptText.value = acceptApproval.value
                    ? "請求承認受付中"
                    : "請求承認を締め切っています"
        }

        const updateInvoiceFixedText = () => {
            invoiceFixedText.value = invoiceFixed.value
                    ? "請求内容確定済み"
                    : "請求内容は未確定です"
        }

        const onTokenExpired = async (resp: ApiResponse) => {
            let reason = logoutMessages.NORMAL_LOGOUT
            if (resp.result === "token_expired") {
                reason = logoutMessages.TOKEN_EXPIRED
            }

            await router.push({
                path: subPaths.LOGGED_OUT,
                query: {
                    logout_message: reason.key
                }
            })
        }

        onMounted(async () => {
            console.log("Home mounted!")
            // shouldWatchStatusChange = false

            const branchResp = await backendApi.inquireBranchList(),
                    statusResp = await backendApi.inquireSiteStatus()

            // console.log(resp)

            if (branchResp.isAuthorized && statusResp.isAuthorized) {
                branches.value = BranchOffice.fromJsonArrToArr(branchResp.value)
                updateInvoiceApprovalStatusUIs(statusResp)
            } else {
                await onTokenExpired(branchResp)
            }

            // shouldWatchStatusChange = true

            loadingOverlayStateStore.commit("fadeout")
        })

        const updateInvoiceApprovalStatusUIs = (statusResp: ApiResponse) => {
            shouldWatchStatusChange = false
            acceptApproval.value = statusResp.value === invoiceStatuses.APPROVAL_ACCEPT
            invoiceFixed.value = statusResp.value === invoiceStatuses.INVOICE_FIXED
            updateApprovalAcceptText()
            updateInvoiceFixedText()

            nextTick(() => {
                shouldWatchStatusChange = true
            })
        }

        const onClickBranch = (branch: BranchOffice) => {

            loadingOverlayStateStore.commit("switchLoadingState", {
                show: true,
                message: `${branch.name}に移動中…`
            })

            router.push({
                path: subPaths.BRANCH_PAGE,
                query: {
                    name: branch.name
                }
            })
        }

        const onClickPaymentAggregation = () => {
            loadingOverlayStateStore.commit("switchLoadingState", {
                show: true,
                message: "日ごとの集計へ移動中…"
            })

            router.push({
                path: subPaths.PAYMENT_AGGREGATION,
            })
        }

        const onClickPostingRecordsDiff = () => {
            loadingOverlayStateStore.commit("switchLoadingState", {
                show: true,
                message: "配布記録データ差分表示…"
            })

            router.push({
                path: subPaths.POSTING_RECORDS_ALL_DIFF,
            })
        }

        const hasAccountingAuth = (): boolean => {
            return authStore.getters.authLevel === authLevels.ADMIN
                || authStore.getters.authLevel === authLevels.ACCOUNTING_ADMIN
                || authStore.getters.authLevel === authLevels.BOARD_MEMBER
        }

        const hasBoardMemberAuth = (): boolean => {
            return authStore.getters.authLevel === authLevels.BOARD_MEMBER
                || authStore.getters.authLevel === authLevels.ADMIN
        }

        const onClickDownloadPrepaymentFile = async () => {

            loadingOverlayStateStore.commit("switchLoadingState", {
                show: true,
                message: "振込用ファイルをダウンロード中…"
            })

            const resp = await backendApi.inquirePrepaymentFile(new Date())
            // console.log(resp)
            if (resp.isAuthorized) {
                await downloadCSV(resp)
            } else {
                await onTokenExpired(resp)
            }

            loadingOverlayStateStore.commit("fadeout")
        }

        const onClickDownloadMonthlyPaymentTXT = async () => {

            loadingOverlayStateStore.commit("switchLoadingState", {
                show: true,
                message: "振込用ファイルをダウンロード中…",
                shouldTimeOut: false,
            })

            const resp = await backendApi.inquireMonthlyPaymentTXT(new Date())
            if (resp.isAuthorized) {
                await downloadShiftJISTXT(resp)
            } else {
                await onTokenExpired(resp)
            }

            loadingOverlayStateStore.commit("fadeout")
        }

        const onClickDownloadMonthlyPaymentExcel = async () => {

            loadingOverlayStateStore.commit("switchLoadingState", {
                show: true,
                message: "月次振り込みExcelファイルをダウンロード中…",
                shouldTimeOut: false,
            })

            const resp = await backendApi.inquireMonthlyPaymentExcel(new Date())
            if (resp.isAuthorized) {
                await downloadExcelFile(resp)
            } else {
                await onTokenExpired(resp)
            }

            loadingOverlayStateStore.commit("fadeout")
        }

        const onClickNegativeAmountListExcel = async () => {

            loadingOverlayStateStore.commit("switchLoadingState", {
                show: true,
                message: "前払い額超過分Excelファイルをダウンロード中…",
                shouldTimeOut: false,
            })

            const resp = await backendApi.inquireNegativeAmountList()
            if (resp.isAuthorized) {
                await downloadExcelFile(resp)
            } else {
                await onTokenExpired(resp)
            }

            loadingOverlayStateStore.commit("fadeout")
        }

        // const downloadTXT = (resp: ApiResponse) => {
        //     const fileData = resp.value.file_data;
        //     const fileName = resp.value.file_name;
        //
        //     const a = document.createElement("a");
        //     // data:text/plain;charset=SHIFT_JIS;base64,
        //     // data:text/plain;charset=UTF-8;base64,
        //     a.href = "data:text/plain;charset=SHIFT_JIS;base64," + fileData
        //
        //     // a.href = "data:text/plain;charset=UTF-8;base64," + fileData
        //     a.download = fileName;
        //     a.type = "text/plain"
        //     a.click();
        // }

        const onClickMoveToHaifuApp = () => {
            // authStoreをクリアするのでリダイレクト前にキャッシュして使用する
            const token = authStore.state.token
            authStore.commit("clearAuth")
            location.href = `${haifuAppBaseUrl}/jwt/login?token=${token}`
        }

        const onClickInvoiceMessages = () => {
            // 請求書メッセージページへの遷移
            loadingOverlayStateStore.commit("switchLoadingState", {
                show: true,
                message: `請求書メッセージページに移動中…`
            })

            router.push({
                path: subPaths.APPROVAL_LIST,
            })
        }

        const onClickDownloadAllInvoicePDFZip = async () => {
            loadingOverlayStateStore.commit("switchLoadingState", {
                show: true,
                message: `全請求書ZIPファイルのダウンロード中…`
            })

            const resp = await backendApi.inquireAllInvoicePDFZip()
            if (resp.isAuthorized) {
                await downloadZIPFile(resp)
            } else {
                await router.push({
                    path: subPaths.LOGGED_OUT,
                    query: {
                        logout_message: logoutMessages.TOKEN_EXPIRED.key
                    }
                })
            }

            loadingOverlayStateStore.commit("fadeout")
        }

        // const onClickUpdateInvoiceStatus = () => {
        //     loadingOverlayStateStore.commit("switchLoadingState", {
        //         show: true,
        //         message: "請求承認受付設定へ移動中…"
        //     })
        //
        //     router.push({
        //         path: subPaths.UPDATE_INVOICE_STATUS,
        //     })
        // }

        return {
            branches,
            onClickBranch,
            onClickPaymentAggregation,
            onClickPostingRecordsDiff,
            hasAccountingAuth,
            hasBoardMemberAuth,
            onClickDownloadPrepaymentFile,
            onClickDownloadMonthlyPaymentTXT,
            FontSizes,
            onClickMoveToHaifuApp,
            onClickInvoiceMessages,
            onClickDownloadAllInvoicePDFZip,
            // onClickUpdateInvoiceStatus,
            acceptApproval,
            invoiceFixed,
            approvalAcceptText,
            invoiceFixedText,
            beforeAcceptApprovalStateChange,
            beforeInvoiceFixedStateChange,
            onClickDownloadMonthlyPaymentExcel,
            onClickNegativeAmountListExcel,
        }
    }
})
