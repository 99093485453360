
import {computed, defineComponent, nextTick, PropType, ref, watch} from "vue";
import {FontSizes} from "@/utils/font-sizes";
import {v4 as uuidv4} from "uuid"

export type popupMenuItemType = {
    itemId: string,
    text: string,
    data: any,
    cb: (itemId: string, data: any) => void
}

export type popupPositionType = {
    x: number,
    y: number
}

export default defineComponent({
    name: "PopupMenu",
    props: {
        show: {
            type: Boolean,
            required: true,
        },
        hide: {
            type: Function as PropType<() => void>,
            required: true,
        },
        items: {
            type: Array as PropType<popupMenuItemType[]>,
            required: true
        },
        position: {
            type: Object as PropType<popupPositionType>,
            required: true,
        },
    },
    setup(props) {

        const itemHeight = 30,
                textWidth = 15,
                frameHeight = ref<number>(itemHeight),
                frameWidth = ref<number>(200),
                posX = ref<number>(0),
                posY = ref<number>(0),
                overlayId = ref<string>(`popup-overlay-${uuidv4()}`)

        const calcHeight = (): number => {
            return itemHeight * props.items.length
        }

        const updateSize = () => {
            frameHeight.value = itemHeight * props.items.length

            let maxTextLength = 0
            props.items.forEach(item => {
                if (item.text.length > maxTextLength) {
                    maxTextLength = item.text.length
                }
            })

            maxTextLength += 2
            frameWidth.value = textWidth * maxTextLength
        }

        const updatePosition = () => {

            const overlayDiv = document.getElementById(overlayId.value) as HTMLDivElement
            if (overlayDiv) {
                const overlayRect = overlayDiv.getBoundingClientRect()
                // console.log(overlayRect)

                posX.value = props.position.x
                posY.value = props.position.y - overlayRect.y

                if (posX.value + frameWidth.value > overlayRect.width) {
                    posX.value = overlayRect.width - frameWidth.value - 5
                }

                // console.log(window.innerHeight)

                // if (posY.value + frameHeight.value > window.innerHeight + window.scrollY) {
                //     posY.value = window.innerHeight + window.scrollY - frameHeight.value - 5
                // }

                // if (posY.value + frameHeight.value > overlayRect.height - overlayRect.y) {
                //     posY.value = overlayRect.height - frameHeight.value - 5 - overlayRect.y
                // }
            }
        }

        watch(() => props.show, () => {
            if (props.show) {
                nextTick(() => {
                    updateSize()
                    updatePosition()
                })
            }
        })

        const onClickItem = (item: popupMenuItemType) => {
            item.cb(item.itemId, item.data)
            props.hide()
        }

        return {
            FontSizes,
            calcHeight,
            itemHeight,
            frameHeight,
            frameWidth,
            posX,
            posY,
            overlayId,
            onClickItem,
        }
    }
})
