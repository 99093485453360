import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import {createDeviceDetector} from "next-vue-device-detector";

import 'bootstrap/dist/css/bootstrap.css' // add
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap-icons/font/bootstrap-icons.css'
import "@/assets/scss/common.scss"

const device = createDeviceDetector()

createApp(App)
    .use(router)
    .use(device)
    .mount('#app')

