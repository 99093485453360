
import {defineComponent, onMounted, onUnmounted, ref} from "vue";
import {backendApi} from "@/utils/backend-api";
import {useRouter} from "vue-router";
import {dateFormat, logoutMessages, subPaths} from "@/utils/consts";
import {InvoiceProcessStatus, invoiceProcessStatuses} from "@/data-models/invoice-process-status";
import {format} from "date-fns";
import {loadingOverlayStateStore} from "@/stores/loading-overlay-state-store";
import {downloadZIPFile} from "@/utils/file-utils";
import {authStore} from "@/stores/auth-store";
import {InvoiceStatusChecker} from "@/utils/invoice-status-checker";
import {InvoiceZipDownloader, zipDownloadState} from "@/utils/invoice-zip-downloader";
import {toDurationString} from "@/utils/date-utils";
import {toPercent} from "@/utils/number-utils";
import {FontSizes} from "@/utils/font-sizes";

export default defineComponent({
    name: "DraftInvoiceDownloader",
    setup() {

        const invoiceProcess = ref<InvoiceProcessStatus>(new InvoiceProcessStatus()),
            downloadingState = ref<string>(zipDownloadState.OUT_OF_PROCESS),
            downloadingRatio = ref<number>(0)

        const router = useRouter()

        let downloadStartTime = 0,
            generateZipStartTime = 0

        const invoiceStatusChecker = new InvoiceStatusChecker()

        // 請求書ステータスの変化を確認するため保持する
        let lastInvoiceProcessStatus = ""

        // 請求書ステータスチェックはここに送信されてくる
        invoiceStatusChecker.onProcessStatus = ivProcess => {
            invoiceProcess.value = ivProcess
            if (invoiceProcess.value.status === invoiceProcessStatuses.ON_PROCESS) {
                if (lastInvoiceProcessStatus !== invoiceProcessStatuses.ON_PROCESS) {
                    // 作成中に切り替わったタイミング
                    generateZipStartTime = Date.now()
                    generateZipElapsedTime = 0
                } else {
                    // 作成中が続いている。
                    generateZipElapsedTime = Date.now() - generateZipStartTime
                }
            }

            lastInvoiceProcessStatus = invoiceProcess.value.status
        }

        invoiceStatusChecker.onError = async msg => {
            console.warn(msg)
            await router.push({
                path: subPaths.LOGGED_OUT,
                query: {
                    logout_message: logoutMessages.TOKEN_EXPIRED.key
                }
            })
        }

        onMounted(() => {
            invoiceStatusChecker.start()
        })

        onUnmounted(() => {
            invoiceStatusChecker.stop()

            if (downloader) {
                downloader.stop()
            }
        })

        const downloadButtonText = (): string => {
            let txt = "ZIPファイル未作成"

            if (downloadingState.value === zipDownloadState.OUT_OF_PROCESS) {
                if (invoiceProcess.value.status === invoiceProcessStatuses.ON_PROCESS) {
                    txt = "ZIPファイル準備中"
                } else if (invoiceProcess.value.status === invoiceProcessStatuses.AVAILABLE) {
                    txt = "ZIPファイルのダウンロード"
                }
            } else  {
                txt = `ダウンロード中`
            }
            return txt
        }

        const canClickDownloadButton = (): boolean => {
            return invoiceProcess.value.status === invoiceProcessStatuses.AVAILABLE
                && downloadingState.value === zipDownloadState.OUT_OF_PROCESS
        }


        const messageText = (): string => {

            let txt = "ZIPファイルは作成されていません。"
            if (downloadingState.value === zipDownloadState.OUT_OF_PROCESS) {
                if (invoiceProcess.value.status === invoiceProcessStatuses.ON_PROCESS) {
                    const geneEstimatedRestTime = generateZipElapsedTime / invoiceProcess.value.processRatio - generateZipElapsedTime
                    txt = `${toDurationString(generateZipElapsedTime)}経過 ${toPercent(invoiceProcess.value.processRatio).toFixed(1)}% 作成済み ［予想残り時間 ${toDurationString(geneEstimatedRestTime)}］`
                } else if (invoiceProcess.value.status === invoiceProcessStatuses.AVAILABLE) {
                    txt = `［${format(invoiceProcess.value.createdAt as Date, dateFormat)}］作成のZIPファイルがダウンロード可能です。`
                }
            } else if (downloadingState.value === zipDownloadState.DOWNLOADING) {
                const dlEstimatedRestTime = downloadElapsedTime / downloadingRatio.value - downloadElapsedTime
                txt = `${toDurationString(downloadElapsedTime)}経過 ${toPercent(downloadingRatio.value).toFixed(1)}% ダウンロード済み ［予想残り時間 ${toDurationString(dlEstimatedRestTime)}］`
            } else if (downloadingState.value === zipDownloadState.SAVING_FILE) {
                txt = `ただいま ZIPファイルを保存中`
            }

            return txt
        }

        const prepareButtonText = (): string => {
            let txt = "ZIPファイル作成"

            if (downloadingState.value === zipDownloadState.OUT_OF_PROCESS) {
                if (invoiceProcess.value.status === invoiceProcessStatuses.ON_PROCESS) {
                    txt = "ZIPファイル準備中"
                } else if (invoiceProcess.value.status === invoiceProcessStatuses.AVAILABLE) {
                    txt = "ZIPファイル再作成"
                }
            } else  {
                txt = `ダウンロード中`
            }

            return txt
        }

        const canClickPrepareButton = (): boolean => {
            return invoiceProcess.value.status !== invoiceProcessStatuses.ON_PROCESS
                && downloadingState.value === zipDownloadState.OUT_OF_PROCESS
        }

        let downloader: InvoiceZipDownloader|null = null

        let downloadElapsedTime = 0,
            generateZipElapsedTime = 0

        const onClickDownloadAllInvoicePDFZip = async () => {

            invoiceStatusChecker.stop()

            downloader = new InvoiceZipDownloader()
            downloader.onError = async msg => {
                console.warn(`DraftInvoiceDownloader::downloader.onError: ${msg}`)
                downloadingState.value = zipDownloadState.OUT_OF_PROCESS

                await router.push({
                    path: subPaths.LOGGED_OUT,
                    query: {
                        logout_message: logoutMessages.TOKEN_EXPIRED.key
                    }
                })
            }

            downloadStartTime = Date.now()

            downloadingState.value = zipDownloadState.DOWNLOADING
            downloader.start((msg, dlRatio) => {
                // console.log(msg, dlRatio)
                downloadingState.value = msg
                downloadingRatio.value = dlRatio
                downloadElapsedTime = Date.now() - downloadStartTime
            }).then(() => {
                downloadingState.value = zipDownloadState.OUT_OF_PROCESS
            })

            invoiceStatusChecker.start()
        }

        const onClickPreparePDFZip = async () => {
            generateZipStartTime = Date.now()
            const resp = await backendApi.requestPrepareAllDraftPDF()
            if (!resp.isAuthorized) {
                await router.push({
                    path: subPaths.LOGGED_OUT,
                    query: {
                        logout_message: logoutMessages.TOKEN_EXPIRED.key
                    }
                })
            }
        }

        const progressBarWidth = (): string => {

            let ratio = 0

            if (downloadingState.value === zipDownloadState.DOWNLOADING) {
                ratio = downloadingRatio.value
            } else {
                ratio = invoiceProcess.value.processRatio
            }

            return Math.round(ratio * 100) + "%"
        }

        return {
            downloadButtonText,
            canClickDownloadButton,
            onClickDownloadAllInvoicePDFZip,
            messageText,
            prepareButtonText,
            canClickPrepareButton,
            onClickPreparePDFZip,
            progressBarWidth,
            invoiceProcess,
            invoiceProcessStatuses,
            downloadingState,
            zipDownloadState,
            FontSizes,
        }
    }
})
