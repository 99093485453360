
import {defineComponent, onMounted, PropType, ref, watch} from "vue";
import {backendApi} from "@/utils/backend-api";
import {useRoute, useRouter} from "vue-router";
import {logoutMessages, subPaths} from "@/utils/consts";
import {amountToString} from "@/utils/string-utils";
import {Staff} from "@/data-models/staff";
import {authStore} from "@/stores/auth-store";
import {authLevels} from "@/data-models/auth";
import {loadingOverlayStateStore} from "@/stores/loading-overlay-state-store";
import {FontSizes} from "@/utils/font-sizes";
import Breadcrumbs, {ancestryType} from "@/components/Breadcrumbs.vue";
import {PaymentAmount} from "@/data-models/payment-amount";
import {isBefore} from "date-fns";
import {PostingRecordDiff} from "@/data-models/posting-record-diff";
import PostingRecordDiffRow from "@/components/PostingRecordDiffRow.vue";
import {DailyDiff} from "@/data-models/daily-diff";
import DailyDiffRow from "@/components/DailyDiffRow.vue";

const tableTabOptions = {
    BY_POSTING_RECORD: "by_posting_record",
    BY_DATE: "by_date"
}

export default defineComponent({
    name: "PostingDataDiffPage",
    components: {DailyDiffRow, PostingRecordDiffRow, Breadcrumbs},
    setup() {

        const staffId = ref<number>(-1),
            staff = ref<Staff>(new Staff()),
            ancestries = ref<ancestryType[]>([] as ancestryType[]),
            searchText = ref<string>(""),
            searchedDiffRecords = ref<PostingRecordDiff[]>([] as PostingRecordDiff[]),
            selectedDiffState = ref<string>("all"),
            selectedTabOption = ref<string>(tableTabOptions.BY_POSTING_RECORD),
            dailyDiffs = ref<DailyDiff[]>([] as DailyDiff[])

        let diffRecords = [] as PostingRecordDiff[],
            addedRecords = [] as PaymentAmount[],
            changedRecords = [] as PaymentAmount[],
            deletedRecords  = [] as PaymentAmount[]


        const route = useRoute(),
            router = useRouter()

        const update = async () => {

            const staffResp = await backendApi.loadStaffById(staffId.value)

            if (staffResp.isAuthorized) {
                staff.value = Staff.fromJson(staffResp.value)

                updateAncestries()
                await updateDiffRecords()
                dailyDiffs.value = DailyDiff.fromPostingRecordDiffArrToArr(diffRecords)
                console.log(dailyDiffs.value)
                updateSearchedDiffRecords()

            } else {
                await router.push({
                    path: subPaths.LOGGED_OUT,
                    query: {
                        logout_message: logoutMessages.TOKEN_EXPIRED.key
                    }
                })
            }
        }

        const updateAncestries = () => {
            ancestries.value = [
                {
                    name: staff.value.displayName + "さん",
                    pathObj: {
                        path: subPaths.MY_PAGE,
                        query: {
                            staff_id: staff.value.staffId,
                        },
                    }
                }
            ]

            if (authStore.getters.authLevel !== authLevels.DIST_STAFF) {
                ancestries.value.unshift({
                    name: staff.value.branch,
                    pathObj: {
                        path: subPaths.BRANCH_PAGE,
                        query: {
                            name: staff.value.branch
                        }
                    }
                })
                ancestries.value.unshift({
                    name: "ホーム",
                    pathObj: subPaths.HOME
                })
            }
        }

        const updateDiffRecords = async () => {
            const diffResp = await backendApi.inquirePostingRecordsDiff(staffId.value)
            console.log(diffResp)
            if (diffResp.isAuthorized) {
                diffRecords = PostingRecordDiff.fromJsonArrToArr(diffResp.value.current_posting_record) as PostingRecordDiff[]

                addedRecords = PaymentAmount.fromJsonArrToArr(diffResp.value.added)
                changedRecords = PaymentAmount.fromJsonArrToArr(diffResp.value.changed)
                deletedRecords = PaymentAmount.fromJsonArrToArr(diffResp.value.deleted)

                for (let i = 0; i < diffRecords.length; i++) {
                    const record1 = diffRecords[i]

                    // addedリストに含まれるか
                    const added = addedRecords.find(data => data.id === record1.id)
                    if (added) {
                        record1.diffState = "added"
                    }

                    const changed = changedRecords.find(data => data.id === record1.id)
                    if (changed) {
                        record1.diffState = "changed"
                        record1.oldData = changed
                    }
                }

                deletedRecords.forEach(data => {
                    const record = data as PostingRecordDiff
                    record.diffState = "deleted"
                    diffRecords.push(record)
                })

                // console.log(diffRecords)

            } else {
                await router.push({
                    path: subPaths.LOGGED_OUT,
                    query: {
                        logout_message: logoutMessages.TOKEN_EXPIRED.key
                    }
                })
            }
        }
        
        const updateSearchedDiffRecords = () => {

            let searched1 = [] as PostingRecordDiff[]

            diffRecords.forEach(record => {
                if (searchText.value.length === 0) {
                    // 検索文字列が空白の時は全件表示する
                    searched1.push(record)
                } else {
                    if (record.stringForSearch.includes(searchText.value)) {
                        searched1.push(record)
                    }
                }
            })

            searchedDiffRecords.value.splice(0)

            searched1.forEach(record => {
                if (selectedDiffState.value === "all") {
                    searchedDiffRecords.value.push(record)
                } else {
                    if (selectedDiffState.value === record.diffState) {
                        searchedDiffRecords.value.push(record)
                    }
                }
            })

            searchedDiffRecords.value.sort((a, b) => {
                if (isBefore(b.date as Date, a.date as Date)) {
                    return 1
                } else if (isBefore(a.date as Date, b.date as Date)) {
                    return -1
                } else {
                    if (a.areaId > b.areaId) {
                        return 1
                    } else if (b.areaId > a.areaId) {
                        return -1
                    } else {
                        return a.productId - b.productId
                    }
                }
            })
        }

        watch(() => searchText.value, () => {
            console.log("searchText.value updated")
            updateSearchedDiffRecords()
        })

        watch(() => selectedDiffState.value, () => {
            console.log("selectedDiffState.value updated")
            updateSearchedDiffRecords()
        })

        onMounted(async () => {

            const stId = route.query.staff_id as string
            if (stId) {
                staffId.value = parseInt(stId)
                await update()
            }

            loadingOverlayStateStore.commit("fadeout")
        })

        const onClickClearSearch = () => {
            searchText.value = ""
            updateSearchedDiffRecords()
        }

        watch(() => selectedTabOption.value, () => {
            console.log(selectedTabOption.value)
        })

        return {
            staffId,
            amountToString,
            staff,
            FontSizes,
            ancestries,
            authStore,
            authLevels,
            searchedDiffRecords,
            searchText,
            onClickClearSearch,
            selectedDiffState,
            tableTabOptions,
            selectedTabOption,
            dailyDiffs,
        }
    }
})
