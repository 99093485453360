
import {defineComponent, onMounted, PropType, ref, watch} from "vue";
import {backendApi} from "@/utils/backend-api";
import {useRoute, useRouter} from "vue-router";
import {invoiceStatuses, logoutMessages, subPaths} from "@/utils/consts";
import {PaymentAmount} from "@/data-models/payment-amount";
import {PaymentHistory} from "@/data-models/payment-history";
import ApplyPrepaymentDialog from "@/components/SubmitPrepaymentDialog.vue";
import {amountToString} from "@/utils/string-utils";
import {Staff} from "@/data-models/staff";
import {authStore} from "@/stores/auth-store";
import {authLevels} from "@/data-models/auth";
import {DailyRecord, paymentTypes} from "@/data-models/daily-record";
import {addMonths, format, isSameMonth} from "date-fns";
import {loadingOverlayStateStore} from "@/stores/loading-overlay-state-store";
import {FontSizes} from "@/utils/font-sizes";
import Breadcrumbs, {ancestryType} from "@/components/Breadcrumbs.vue";
import ja from "date-fns/locale/ja"
import {toastStore} from "@/stores/toast-store";

const stopTypes = {
    MONTH: "month",
    ADVANCE: "advance",
    BOTH: "both",
    RESUME_MONTH: "resume_month",
    RESUME_ADVANCE: "resume_advance",
    RESUME_BOTH: "resume_both",
}

export default defineComponent({
    name: "PaymentStopPage",
    components: {Breadcrumbs},
    setup() {

        const staffId = ref<number>(-1),
            staff = ref<Staff>(new Staff()),
            ancestries = ref<ancestryType[]>([] as ancestryType[]),
            acceptApproval = ref<boolean>(false),
            monthPaymentStopped = ref<boolean>(false),
            prepaymentStopped = ref<boolean>(false)


        const route = useRoute(),
            router = useRouter()

        const update = async () => {

            const staffResp = await backendApi.loadStaffById(staffId.value),
                    statusResp = await backendApi.inquireSiteStatus()

            if (staffResp.isAuthorized && statusResp.isAuthorized) {
                staff.value = Staff.fromJson(staffResp.value)

                ancestries.value = [
                    {
                        name: "ホーム",
                        pathObj: subPaths.HOME
                    },
                    {
                        name: staff.value.branch,
                        pathObj: {
                            path: subPaths.BRANCH_PAGE,
                            query: {
                                name: staff.value.branch
                            }
                        }
                    },
                    {
                        name: `${staff.value.displayName}さんのページ`,
                        pathObj: {
                            path: subPaths.MY_PAGE,
                            query: {
                                staff_id: staff.value.staffId,
                            }
                        }
                    }
                ]

                acceptApproval.value = statusResp.value === invoiceStatuses.APPROVAL_ACCEPT

            } else {
                await router.push({
                    path: subPaths.LOGGED_OUT,
                    query: {
                        logout_message: logoutMessages.TOKEN_EXPIRED.key
                    }
                })
            }
        }

        const updatePaymentStopStatus = async () => {
            const stopStatusResp = await backendApi.inquirePaymentStopStatus(staffId.value)
            if (stopStatusResp.isSuccessful) {
                console.log(stopStatusResp.value)
                monthPaymentStopped.value = stopStatusResp.value.month
                prepaymentStopped.value = stopStatusResp.value.advance
            } else {
                await router.push({
                    path: subPaths.LOGGED_OUT,
                    query: {
                        logout_message: logoutMessages.TOKEN_EXPIRED.key
                    }
                })
            }
        }

        onMounted(async () => {

            const stId = route.query.staff_id as string
            if (stId) {
                staffId.value = parseInt(stId)
                await update()
                await updatePaymentStopStatus()
            }

            loadingOverlayStateStore.commit("fadeout")
        })

        const switchStopCore = async (stopType: string): Promise<boolean> => {
            const resp = await backendApi.requestPaymentStop(staffId.value, stopType)
            if (resp.isSuccessful) {
                return true
            } else {
                await router.push({
                    path: subPaths.LOGGED_OUT,
                    query: {
                        logout_message: logoutMessages.TOKEN_EXPIRED.key
                    }
                })
                return false
            }
        }

        const onClickSwitchMonthStop = async () => {
            const stopType = monthPaymentStopped.value ? stopTypes.RESUME_MONTH : stopTypes.MONTH
            const success = await switchStopCore(stopType)
            if (success) {
                monthPaymentStopped.value = !monthPaymentStopped.value

                const message = monthPaymentStopped.value ? "月払いを停止しました" : "月払いを再開しました"
                toastStore.commit("post", {message})
            }
        }

        const onClickSwitchPrepaymentStop = async () => {
            const stopType = prepaymentStopped.value ? stopTypes.RESUME_ADVANCE : stopTypes.ADVANCE
            const success = await switchStopCore(stopType)
            if (success) {
                prepaymentStopped.value = !prepaymentStopped.value
                const message = prepaymentStopped.value ? "前払いを停止しました" : "前払いを再開しました"
                toastStore.commit("post", {message})
            }
        }

        const onClickStopBoth = async () => {
            const success = await switchStopCore(stopTypes.BOTH)
            if (success) {
                monthPaymentStopped.value = true
                prepaymentStopped.value = true
                toastStore.commit("post", {message: "月払い・前払いを停止しました"})
            }
        }

        const onClickResumeBoth = async () => {
            const success = await switchStopCore(stopTypes.RESUME_BOTH)
            if (success) {
                monthPaymentStopped.value = false
                prepaymentStopped.value = false
                toastStore.commit("post", {message: "月払い・前払いを再開しました"})
            }
        }

        return {
            staffId,
            staff,
            FontSizes,
            ancestries,
            authStore,
            authLevels,
            acceptApproval,
            monthPaymentStopped,
            prepaymentStopped,
            onClickSwitchMonthStop,
            onClickSwitchPrepaymentStop,
            onClickStopBoth,
            onClickResumeBoth,
        }
    }
})
