import {Staff} from "@/data-models/staff";
import {ApprovalMessage} from "@/data-models/approval-message";

export class InvoiceApproval {

    id = -1
    staffId = -1
    staff = new Staff()
    message = ""
    messages = [] as ApprovalMessage[]
    state = ""
    accept = false

    static fromJson(obj: any): InvoiceApproval {
        const apprv = new InvoiceApproval()
        apprv.id = obj.id
        apprv.staffId = obj.staff_id
        apprv.message = obj.message
        apprv.messages = ApprovalMessage.fromJsonArrToArr(obj.messages)
        apprv.state = obj.state
        apprv.accept = obj.accept

        apprv.staff = Staff.fromJson(obj.staff)
        return apprv
    }

    static fromJsonArrToArr (objArr: Array<any>): InvoiceApproval[] {
        const arr = [] as InvoiceApproval[]
        objArr.forEach(obj => {
            arr.push(this.fromJson(obj))
        })
        return arr
    }

    get vForKey(): string {
        return `invoice_approval__${this.id.toString().padStart(6, "0")}`
    }

    get stringForSearchWithBranch(): string {
        const messageLines = this.message.split("\n\r")
        return `${this.staffId} ${this.staff.displayName} ${this.staff.userName} ${this.staff.branch} ${messageLines.join(" ")}`
    }

    get stringForSearch(): string {
        const messageLines = this.message.split("\n\r")
        return `${this.staffId} ${this.staff.displayName} ${this.staff.userName} ${messageLines.join(" ")}`
    }

    get messageInHTML(): string {
        return this.message.replaceAll("\n\r", "<br>")
    }

    get messageLineCount(): number {
        return (this.message.match(/\n\r/g) || []).length + 1
    }
}