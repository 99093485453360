
import {defineComponent, onMounted, ref, watch} from "vue";
import {loadingOverlayStateStore} from "@/stores/loading-overlay-state-store";
import Breadcrumbs, {ancestryType} from "@/components/Breadcrumbs.vue";
import {logoutMessages, subPaths} from "@/utils/consts";
import {authStore} from "@/stores/auth-store";
import {authLevels} from "@/data-models/auth";
import {Staff} from "@/data-models/staff";
import {useRoute, useRouter} from "vue-router";
import {FontSizes} from "@/utils/font-sizes";
import {backendApi} from "@/utils/backend-api";
import {addMonths, format, isBefore} from "date-fns";
import ja from "date-fns/locale/ja";
import {PaymentAmount} from "@/data-models/payment-amount";
import {PaymentHistory} from "@/data-models/payment-history";
import {paymentTypes} from "@/data-models/daily-record";
import {InvoiceApproval} from "@/data-models/invoice-approval";
import {ApiResponse} from "@/data-models/api-response";
import {downloadExcelFile} from "@/utils/file-utils";

export default defineComponent({
    name: "InvoiceOfMonthPage",
    components: {
        Breadcrumbs,
    },
    setup() {

        const approvedText = "請求書承認および発行済み",
                disapprovedText = "請求書未承認"

        const staffId = ref<number>(-1),
                ancestries = ref<ancestryType[]>([] as ancestryType[]),
                staff = ref<Staff>(new Staff()),
                lastMonthText = ref<string>("--月"),
                searchedPayAmounts = ref<PaymentAmount[]>([] as PaymentAmount[]),
                searchText = ref<string>(""),
                totalAmount = ref<number>(0),
                prepaidAmount = ref<number>(0),
                compensationBalance = ref<number>(0),
                billingAmount = ref<number>(0),
                transferFee = ref<number>(0),
                messageText = ref<string>(""),
                submitBoxHeight = ref<number>(40),
                isSubmitBoxOpen = ref<boolean>(false),
                invoiceApproval = ref<InvoiceApproval>(new InvoiceApproval()),
                approvalStateText = ref<string>(""),
                messageThreadHeightMeasureDummyDiv = ref<HTMLDataElement>()

        let payAmounts = [] as PaymentAmount[]

        const route = useRoute(),
                router = useRouter()



        const update = async () => {
            const staffResp = await backendApi.loadStaffById(staffId.value)

            if (staffResp.isAuthorized) {
                staff.value = Staff.fromJson(staffResp.value)

                updateAncestries()
                await updatePaymentAmounts()
                updateTotalAmount()
                await updatePrepaidAmount()
                compensationBalance.value = totalAmount.value - prepaidAmount.value
                await updateBankTransferFee()
                billingAmount.value = compensationBalance.value - transferFee.value

                await updateInvoiceApproval()
                updateSubmitBoxHeight()


            } else {
                await router.push({
                    path: subPaths.LOGGED_OUT,
                    query: {
                        logout_message: logoutMessages.TOKEN_EXPIRED.key
                    }
                })
            }
        }

        const updatePaymentAmounts = async () => {
            const pAmountResp = await backendApi.inquirePaymentAmountsWithProductName(staffId.value, 1)
            console.log(pAmountResp)
            if (pAmountResp.isAuthorized) {
                payAmounts = PaymentAmount.fromJsonArrToArr(pAmountResp.value)
                updateSearchedPayAmounts()
                // console.log(payAmounts)
            } else {
                await router.push({
                    path: subPaths.LOGGED_OUT,
                    query: {
                        logout_message: logoutMessages.TOKEN_EXPIRED.key
                    }
                })
            }
        }

        const onClickDownloadInvoiceDetailExcel = async () => {

            loadingOverlayStateStore.commit("switchLoadingState", {
                show: true,
                message: "請求明細Excelファイルダウンロード…",
                shouldTimeOut: false,
            })

            const resp = await backendApi.inquireMonthlyInvoiceDetailExcel(staffId.value, 1)
            if (resp.isAuthorized) {
                await downloadExcelFile(resp)
            } else {
                await router.push({
                    path: subPaths.LOGGED_OUT,
                    query: {
                        logout_message: logoutMessages.TOKEN_EXPIRED.key
                    }
                })
            }
            loadingOverlayStateStore.commit("fadeout")
        }

        const updateSearchedPayAmounts = () => {
            searchedPayAmounts.value.splice(0)
            payAmounts.forEach(pAmount => {
                if (searchText.value.length === 0) {
                    // 検索文字列が空白の時は全件表示する
                    searchedPayAmounts.value.push(pAmount)
                } else {
                    if (pAmount.stringForSearch.includes(searchText.value)) {
                        searchedPayAmounts.value.push(pAmount)
                    }
                }
            })

            searchedPayAmounts.value.sort((a, b) => {
                if (isBefore(b.date as Date, a.date as Date)) {
                    return 1
                } else if (isBefore(a.date as Date, b.date as Date)) {
                    return -1
                } else {
                    if (a.areaId > b.areaId) {
                        return 1
                    } else if (b.areaId > a.areaId) {
                        return -1
                    } else {
                        return a.productId - b.productId
                    }
                }
            })
        }

        const updateTotalAmount = () => {
            totalAmount.value = 0
            payAmounts.forEach(pAmount => {
                totalAmount.value += pAmount.amount
            })
            totalAmount.value = Math.floor(totalAmount.value)
        }

        const updatePrepaidAmount = async () => {

            prepaidAmount.value = 0

            const historyResp = await backendApi.inquirePaymentHistories(staff.value.staffId, 1)
            if (historyResp.isAuthorized) {
                const histories = PaymentHistory.fromJsonArrToArr(historyResp.value)
                histories.forEach(hist => {
                    if (hist.paymentType === paymentTypes.ADVANCE_PAYMENT) {
                        prepaidAmount.value += hist.amount
                    }
                })

                prepaidAmount.value = Math.floor(prepaidAmount.value)

            } else {
                await router.push({
                    path: subPaths.LOGGED_OUT,
                    query: {
                        logout_message: logoutMessages.TOKEN_EXPIRED.key
                    }
                })
            }
        }

        const updateBankTransferFee = async () => {
            const transferFeeResp = await backendApi.inquireBankTransferFeeForMonthlyPay(staff.value.staffId, compensationBalance.value)
            if (transferFeeResp.isAuthorized) {
                // console.log(transferFeeResp.value)
                transferFee.value = transferFeeResp.value
            } else {
                await router.push({
                    path: subPaths.LOGGED_OUT,
                    query: {
                        logout_message: logoutMessages.TOKEN_EXPIRED.key
                    }
                })
            }
        }

        const updateInvoiceApproval = async () => {
            const resp = await backendApi.inquireInvoiceMessageByStaffId(staff.value.staffId)
            console.log(resp)
            if (resp.isAuthorized) {
                const approvalArr = InvoiceApproval.fromJsonArrToArr(resp.value)
                if (approvalArr.length > 0) {
                    invoiceApproval.value = approvalArr[0]
                }
                updateApprovalStateText()
            } else {
                await router.push({
                    path: subPaths.LOGGED_OUT,
                    query: {
                        logout_message: logoutMessages.TOKEN_EXPIRED.key
                    }
                })
            }
        }

        const updateAncestries = () => {
            ancestries.value = [
                {
                    name: staff.value.displayName + "さん",
                    pathObj: {
                        path: subPaths.MY_PAGE,
                        query: {
                            staff_id: staff.value.staffId,
                        },
                    }
                }
            ]

            if (authStore.getters.authLevel !== authLevels.DIST_STAFF) {
                ancestries.value.unshift({
                    name: staff.value.branch,
                    pathObj: {
                        path: subPaths.BRANCH_PAGE,
                        query: {
                            name: staff.value.branch
                        }
                    }
                })
                ancestries.value.unshift({
                    name: "ホーム",
                    pathObj: subPaths.HOME
                })
            }
        }

        watch(() => searchText.value, () => {
            console.log("searchText.value updated")
            updateSearchedPayAmounts()
        })

        const onClickClearSearch = () => {
            searchText.value = ""
        }

        const onClickSubmitInvoice = async () => {
            console.log("onClickSubmitInvoice")

            loadingOverlayStateStore.commit("switchLoadingState", {
                show: true,
                message: "請求書発行中…",
                shouldTimeOut: false,
            })

            isSubmitBoxOpen.value = false
            updateSubmitBoxHeight()

            let approvalResp: ApiResponse

            // 請求内容を承認したというメッセージを送る
            if (invoiceApproval.value.id === -1) {

                // 承認メッセージの新規発行
                approvalResp = await backendApi.inquireForCreateApproval(staff.value.staffId, approvedText, true)

            } else {

                // 承認メッセージの更新
                approvalResp = await backendApi.inquireForUpdateApproval(staff.value.staffId, invoiceApproval.value.id, approvedText, true)
            }

            console.log(approvalResp)

            if (approvalResp.isAuthorized) {

                // 承認メッセージが受理されたことを確認して…
                const approvalArr = InvoiceApproval.fromJsonArrToArr(approvalResp.value)
                if (approvalArr.length > 0) {
                    invoiceApproval.value = approvalArr[0]
                }

                updateApprovalStateText()

                // PDF表示ページへ遷移する。
                await router.push({
                    path: subPaths.MY_PAGE_INVOICE_OF_MONTH_PDF,
                    query: {
                        staff_id: staffId.value
                    }
                })

            } else {
                await router.push({
                    path: subPaths.LOGGED_OUT,
                    query: {
                        logout_message: logoutMessages.TOKEN_EXPIRED.key
                    }
                })
                loadingOverlayStateStore.commit("fadeout")
            }
        }

        onMounted(async () => {

            loadingOverlayStateStore.commit("switchLoadingState", {
                show: true,
                message: "月払い請求書ページ…",
                shouldTimeOut: false,
            })

            const stId = route.query.staff_id as string
            if (stId) {
                staffId.value = parseInt(stId)
                await update()
            }

            lastMonthText.value = format(addMonths(new Date(), -1), "MMM", { locale:ja })

            loadingOverlayStateStore.commit("fadeout")

            watchAndUpdateSubmitBoxOpenHeight()
        })

        const onClickSubmitBoxHeader = () => {
            isSubmitBoxOpen.value = !isSubmitBoxOpen.value
            updateSubmitBoxHeight()
        }


        const submitBoxOpenHeight = ref<number>(250)
        let messageThreadOldHeight = 0
        const watchAndUpdateSubmitBoxOpenHeight = () => {
            setInterval(() => {
                if (messageThreadHeightMeasureDummyDiv.value) {
                    // console.log("messageThreadHeightMeasureDummyDiv.value.clientHeight", messageThreadHeightMeasureDummyDiv.value.clientHeight)
                    const messageThreadHeight = messageThreadHeightMeasureDummyDiv.value.clientHeight
                    if (messageThreadHeight !== messageThreadOldHeight) {
                        console.log("messageThreadHeight", messageThreadHeight)
                        messageThreadOldHeight = messageThreadHeight
                        submitBoxOpenHeight.value = messageThreadHeight + 250
                        updateSubmitBoxHeight()
                    }
                }
            }, 50)
        }

        const updateSubmitBoxHeight = () => {
            if (isSubmitBoxOpen.value) {
                submitBoxHeight.value = submitBoxOpenHeight.value
            } else {
                submitBoxHeight.value = 40
            }
        }

        const onClickSubmitMessage = async () => {

            let resp: ApiResponse

            if (invoiceApproval.value.id === -1) {
                resp = await backendApi.inquireForCreateApproval(staff.value.staffId, messageText.value, false)

            } else {
                resp = await backendApi.inquireForUpdateApproval(staff.value.staffId, invoiceApproval.value.id, messageText.value, false)
            }

            console.log(resp)

            if (resp.isAuthorized) {
                const approvalArr = InvoiceApproval.fromJsonArrToArr(resp.value)
                if (approvalArr.length > 0) {
                    invoiceApproval.value = approvalArr[0]
                }

                messageText.value = ""
                updateApprovalStateText()
                updateSubmitBoxHeight()

            } else {
                await router.push({
                    path: subPaths.LOGGED_OUT,
                    query: {
                        logout_message: logoutMessages.TOKEN_EXPIRED.key
                    }
                })
            }
        }

        const canClickSubmitMessage = (): boolean => {
            return messageText.value.length > 0
        }

        const existsApprovalMessageContent = (): boolean => {
            return invoiceApproval.value.id !== -1
                    && invoiceApproval.value.message.length > 0
        }


        const updateApprovalStateText = () => {
            approvalStateText.value = invoiceApproval.value.accept
                    ? approvedText
                    : disapprovedText
        }

        const downloadInvoicePDF = (resp: ApiResponse) => {

            const fileData = resp.value.file_data;
            const fileName = resp.value.file_name;

            const a = document.createElement("a");
            a.href = "data:application/pdf;charset=UTF-8;base64," + fileData
            a.download = fileName;
            a.type = "application/pdf"
            a.click();
        }

        return {
            staff,
            ancestries,
            FontSizes,
            lastMonthText,
            searchText,
            onClickClearSearch,
            searchedPayAmounts,
            totalAmount,
            prepaidAmount,
            compensationBalance,
            billingAmount,
            transferFee,
            onClickSubmitInvoice,
            messageText,
            isSubmitBoxOpen,
            submitBoxHeight,
            onClickSubmitBoxHeader,
            onClickSubmitMessage,
            canClickSubmitMessage,
            invoiceApproval,
            existsApprovalMessageContent,
            approvalStateText,
            approvedText,
            disapprovedText,
            messageThreadHeightMeasureDummyDiv,
            onClickDownloadInvoiceDetailExcel,
        }
    }
})
