import {parse} from "date-fns";

export class InvoiceStatus {
    yearMonth = new Date()
    invoice = new Invoice()

    static fromJson(obj: any): InvoiceStatus {
        const status = new InvoiceStatus()
        status.yearMonth = parse(obj.yyyymm, "yyyy/MM", new Date())
        status.invoice = Invoice.fromJson(obj.invoice)
        return status
    }

    static fromJsonArrToArr(objArr: any[]): InvoiceStatus[] {
        const arr = [] as InvoiceStatus[]
        objArr.forEach(obj => {
            arr.push(InvoiceStatus.fromJson(obj))
        })
        return arr
    }
}

class Invoice {
    amount = 0
    transferFee = 0
    staffId = 0

    static fromJson(obj: any): Invoice {
        const inv = new Invoice()
        inv.amount = obj.amount
        inv.transferFee = obj.transfer_fee
        inv.staffId = obj.staff_id
        return inv
    }
}