
import {defineComponent, onMounted, ref} from "vue";
import {backendApi} from "@/utils/backend-api";
import {datetimeFormat, subPaths} from "@/utils/consts";
import {useRoute, useRouter} from "vue-router";
import {loadingOverlayStateStore} from "@/stores/loading-overlay-state-store";
import {FontSizes} from "@/utils/font-sizes";
import Breadcrumbs, {ancestryType} from "@/components/Breadcrumbs.vue";
import {PostingRecordAllDiffSummary} from "@/data-models/posting-record-all-diff-summary";
import {format} from "date-fns";
import {Staff} from "@/data-models/staff";
import {PostingRecordBranchDiffSummary, StaffDiffEntity} from "@/data-models/posting-record-branch-diff-summary";

export default defineComponent({
    name: "PostingRecordsAllDiff",
    components: {Breadcrumbs},
    setup() {

        const branchName = ref<string>(""),
            ancestries = ref<ancestryType[]>([] as ancestryType[]),
            branchDiffSummary = ref<PostingRecordBranchDiffSummary>(new PostingRecordBranchDiffSummary())

        // const recordDiffSummary = ref<PostingRecordAllDiffSummary>(new PostingRecordAllDiffSummary())

        const router = useRouter()

        onMounted(async () => {

            const branchNameQuery = useRoute().query.name as string
            if (branchNameQuery) {
                branchName.value = branchNameQuery

                ancestries.value = [
                    {
                        name: "ホーム",
                        pathObj: subPaths.HOME
                    },
                    {
                        name: branchName.value,
                        pathObj: {
                            path: subPaths.BRANCH_PAGE,
                            query: {
                                name: branchName.value
                            }
                        }
                    }
                ]

                const diffResp = await backendApi.inquirePostingRecordsBranchDiff(branchName.value)
                console.log(diffResp.value)

                const staffListResp = await backendApi.inquireStaffList(branchName.value, 0)
                console.log(staffListResp.value)

                if (diffResp.isAuthorized && staffListResp.isAuthorized) {

                    const staffList = Staff.fromJsonArrToArr(staffListResp.value)

                    branchDiffSummary.value = PostingRecordBranchDiffSummary.fromJson(diffResp.value)
                    branchDiffSummary.value.entities.forEach(staffDiff => {
                        const staff = staffList.find(staff => staff.staffId === staffDiff.staffId)
                        if (staff) {
                            staffDiff.name = staff.displayName
                        }
                    })

                    console.log(branchDiffSummary.value)
                } else {
                    await router.push(subPaths.LOGIN)
                }


            }

            loadingOverlayStateStore.commit("fadeout")
        })

        const updatedDTString = (): string => {
            return format(branchDiffSummary.value.updatedAt as Date, datetimeFormat)
        }

        const onClickStaffDiffSummary = (staffId: number, staffName: string) => {

            loadingOverlayStateStore.commit("switchLoadingState", {
                show: true,
                message: `${staffName}さんの配布記録一覧に移動中…`,
                shouldTimeOut: false,
            })

            router.push({
                path: subPaths.MY_PAGE_POSTING_DATA_DIFF,
                query: {
                    staff_id: staffId
                }
            })
        }

        return {
            FontSizes,
            branchName,
            ancestries,
            branchDiffSummary,
            updatedDTString,
            onClickStaffDiffSummary,
        }
    }
})
