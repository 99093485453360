
import {defineComponent, PropType} from "vue";
import {InvoiceApproval} from "@/data-models/invoice-approval";
import {useRoute, useRouter} from "vue-router";
import {loadingOverlayStateStore} from "@/stores/loading-overlay-state-store";
import {subPaths} from "@/utils/consts";
import {FontSizes} from "@/utils/font-sizes";

export default defineComponent({
    name: "InvoiceApprovalListCell",
    props: {
        invoiceApproval: {
            type: Object as PropType<InvoiceApproval>,
            required: true
        },
        isForBranchPage: {
            type: Boolean,
            default: false,
        }
    },
    setup(props) {

        const router = useRouter()

        const approvedText = "請求額承認済み",
            approvedByBranch = "支店による承認済み",
                disapprovedText = "請求額未承認"

        const approvalStateText = (): string => {
            return props.invoiceApproval.accept
                ? props.invoiceApproval.state === "approved_by_branch"
                    ? approvedByBranch
                    : approvedText
                : disapprovedText
        }

        const onClickStaffLink = () => {
            loadingOverlayStateStore.commit("switchLoadingState", {
                show: true,
                message: "スタッフページへ移動中…",
                shouldTimeOut: false,
            })
            router.push({
                path: subPaths.MY_PAGE_INVOICE_OF_MONTH,
                query: {
                    staff_id: props.invoiceApproval.staffId
                }
            })
        }

        const stateStyleClass = (): string => {
            return props.invoiceApproval.accept
                ? props.invoiceApproval.state === "approved_by_branch"
                    ? "invoice-approved-by-branch"
                    : "invoice-approved"
                : "invoice-disapproved"
        }

        return {
            approvalStateText,
            onClickStaffLink,
            FontSizes,
            stateStyleClass,
        }
    }
})
