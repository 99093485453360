
import {defineComponent, onMounted, onUnmounted, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import {backendApi} from "@/utils/backend-api";
import {Staff} from "@/data-models/staff";
import {dateFormat, logoutMessages, subPaths} from "@/utils/consts";
import {loadingOverlayStateStore} from "@/stores/loading-overlay-state-store";
import ClearableInput from "@/components/ClearableInput.vue";
import {FontSizes} from "@/utils/font-sizes";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import PopupMenu, {popupMenuItemType, popupPositionType} from "@/components/PopupMenu.vue";
import MenuButton from "@/components/MenuButton.vue";
import {confirmDialogStateStore} from "@/stores/confirm-dialog-state-store";
import {toastStore} from "@/stores/toast-store";
import {InvoiceProcessStatus, invoiceProcessStatuses} from "@/data-models/invoice-process-status";
import {format} from "date-fns";
import {InvoiceStatusChecker} from "@/utils/invoice-status-checker";
import {InvoiceZipDownloader, zipDownloadState} from "@/utils/invoice-zip-downloader";
import {toPercent} from "@/utils/number-utils";

const staffPopupMenuItemIds = {
    RESET_STAFF_PASSWORD: "staff_popup_menu_item__reset_staff_password"
}

export default defineComponent({
    name: "Branch",
    components: {MenuButton, PopupMenu, Breadcrumbs, ClearableInput},
    setup() {

        const route = useRoute(),
            router = useRouter()

        let staffs = [] as Staff[]

        const searchedStaffs = ref<Staff[]>([] as Staff[]),
            branchName = ref<string>(""),
            searchText = ref<string>(""),
            showStaffMenuPopup = ref<boolean>(false),
            staffMenuPopupPos = ref<popupPositionType>({x: 0, y: 0}),
            staffMenuItems = ref<popupMenuItemType[]>([] as popupMenuItemType[]),
            downloadingState = ref<string>(zipDownloadState.OUT_OF_PROCESS),
            downloadingRatio = ref<number>(0)
            
        let activeFlag = ref<string>("0")

        const onClickStaffDetail = (staffId: number) => {
            loadingOverlayStateStore.commit("switchLoadingState", {
                show: true,
                message: "スタッフページへ移動中…"
            })
            router.push({
                path: subPaths.MY_PAGE,
                query: {
                    staff_id: staffId
                }
            })
        }

        const updateSearchedStaffs = (searchText: string) => {
            searchedStaffs.value.splice(0)
            staffs.forEach(staff => {
                if (searchText.length === 0 || staff.stringForSearch.includes(searchText)) {
                    searchedStaffs.value.push(staff)
                }
            })
        }

        const onClickBranchApprovalList = () => {
            loadingOverlayStateStore.commit("switchLoadingState", {
                show: true,
                message: `${branchName.value}支店の請求承認メッセージ一覧へ移動中…`
            })
            router.push({
                path: subPaths.BRANCH_PAGE_APPROVAL_LIST,
                query: {
                    name: branchName.value
                }
            })
        }

        const onClickBranchDiffList = () => {
            loadingOverlayStateStore.commit("switchLoadingState", {
                show: true,
                message: `${branchName.value}支店のデータ差分概要へ移動中…`
            })
            router.push({
                path: subPaths.POSTING_RECORDS_BRANCH_DIFF,
                query: {
                    name: branchName.value
                }
            })
        }

        watch(() => searchText.value, () => {
            updateSearchedStaffs(searchText.value)
        })

        watch(() => activeFlag.value, () => {
            inquireStaffListBackend()
        })

        const invoiceStatusChecker = new InvoiceStatusChecker()

        invoiceStatusChecker.onProcessStatus = ivProcess => {
            // console.log(ivProcess)
            invoiceProcess.value = ivProcess
        }

        invoiceStatusChecker.onError = async msg => {
            console.warn(msg)
            await router.push({
                path: subPaths.LOGGED_OUT,
                query: {
                    logout_message: logoutMessages.TOKEN_EXPIRED.key
                }
            })
        }

        let downloader: InvoiceZipDownloader|null = null

        const inquireStaffListBackend = async () => {
            const branchNameQuery = route.query.name as string
            if (branchNameQuery) {
                branchName.value = branchNameQuery
                const resp = await backendApi.inquireStaffList(branchNameQuery, Number(activeFlag.value))
                // console.log(resp)
                if (resp.isAuthorized) {
                    staffs = Staff.fromJsonArrToArr(resp.value)
                    updateSearchedStaffs("")

                    invoiceStatusChecker.start()

                } else {
                    await router.push(subPaths.LOGIN)
                }
            }
        }

        onMounted(async () => {
            await inquireStaffListBackend()
            loadingOverlayStateStore.commit("fadeout")
        })

        onUnmounted(() => {
            invoiceStatusChecker.stop()
            if (downloader) {
                downloader.stop()
            }
        })

        const onClickDownloadBranchInvoicePDFZip = async () => {

            // console.log("onClickDownloadBranchInvoicePDFZip")

            invoiceStatusChecker.stop()

            downloader = new InvoiceZipDownloader()

            downloader.onError = msg => {
                console.warn(msg)
            }

            downloader.start((msg, dlRatio) => {
                // console.log(msg, dlRatio)
                downloadingState.value = msg
                downloadingRatio.value = dlRatio

                if (downloadingState.value === zipDownloadState.FILE_NOT_FOUND) {
                    toastStore.commit("post", {
                        message: `${branchName.value}支店の請求書ZIPファイルは存在しません。`,
                        width: 300,
                    })
                }

            }, branchName.value).then(() => {
                downloadingState.value = zipDownloadState.OUT_OF_PROCESS
            })

            invoiceStatusChecker.start()
        }

        const onClickStaffMenu = (staff: Staff, btnDiv: HTMLDivElement) => {

            const divRect = btnDiv.getBoundingClientRect()
            staffMenuPopupPos.value = {
                x: divRect.x,
                y: divRect.y
            }

            console.log(staffMenuPopupPos.value, window.scrollY)

            staffMenuItems.value.splice(0)
            staffMenuItems.value.push({
                itemId: staffPopupMenuItemIds.RESET_STAFF_PASSWORD,
                text: "パスワードをリセット",
                data: staff,
                cb: (itemId: string, staff: Staff) => {
                    if (itemId === staffPopupMenuItemIds.RESET_STAFF_PASSWORD) {
                        confirmDialogStateStore.commit("switchConfirmDialog", {
                            show: true,
                            title: "パスワードのリセット",
                            message: `${staff.displayName}さんのパスワードをリセットして、社員IDと同じにしますか？`,
                            affirmativeText: "リセット",
                            affirmCallback: async (affirmed: boolean) => {
                                if (affirmed) {
                                    const resetResp = await backendApi.inquireResetPassword(staff.staffId.toString())
                                    if (resetResp.isAuthorized) {
                                        if (resetResp.isSuccessful) {
                                            toastStore.commit("post", {
                                                message: "パスワードをリセットしました。"
                                            })
                                        } else {
                                            toastStore.commit("post", {
                                                message: "パスワードのリセットに失敗しました。"
                                            })
                                        }
                                    } else {
                                        await router.push({
                                            path: subPaths.LOGGED_OUT,
                                            query: {
                                                logout_message: logoutMessages.TOKEN_EXPIRED.key
                                            }
                                        })
                                    }

                                }
                            },
                        })
                    }
                }
            })

            showStaffMenuPopup.value = true
        }

        const hideStaffMenuPopup = () => {
            showStaffMenuPopup.value = false
        }

        const invoiceProcess = ref<InvoiceProcessStatus>(new InvoiceProcessStatus())

        const downloadZipButtonText = (): string => {
            let txt = "支店の全請求書ZIPファイル"

            if (downloadingState.value === zipDownloadState.DOWNLOADING) {
                txt = `${branchName.value}支店の全請求書ZIPファイルダウンロード中 ${Math.round(toPercent(downloadingRatio.value))}%`
            } else {
                if (invoiceProcess.value.status === invoiceProcessStatuses.NOT_AVAILABLE) {
                    txt = `${branchName.value}支店の全請求書ZIPファイル 未作成`
                } else if (invoiceProcess.value.status === invoiceProcessStatuses.AVAILABLE) {
                    txt = `${branchName.value}支店の全請求書ZIPファイル［${format(invoiceProcess.value.createdAt as Date, dateFormat)}］作成`
                } else if (invoiceProcess.value.status === invoiceProcessStatuses.ON_PROCESS) {
                    txt = `${branchName.value}支店の全請求書ZIPファイル ${Math.round(toPercent(invoiceProcess.value.processRatio))}% 準備中...`
                }
            }
            return txt
        }

        const progressWidth = (): string => {
            return `${Math.round(downloadingRatio.value * 100)}%`
        }

        const canClickDownloadZipButton = (): boolean => {
            return invoiceProcess.value.status === invoiceProcessStatuses.AVAILABLE
                && downloadingState.value !== zipDownloadState.DOWNLOADING
        }

        return {
            branchName,
            onClickStaffDetail,
            searchedStaffs,
            searchText,
            FontSizes,
            ancestries: [
                {
                    name: "ホーム",
                    pathObj: subPaths.HOME
                }
            ],
            onClickBranchApprovalList,
            onClickDownloadBranchInvoicePDFZip,
            onClickStaffMenu,
            showStaffMenuPopup,
            hideStaffMenuPopup,
            staffMenuPopupPos,
            staffMenuItems,
            downloadZipButtonText,
            canClickDownloadZipButton,
            progressWidth,
            downloadingState,
            zipDownloadState,
            onClickBranchDiffList,
            activeFlag,
            inquireStaffListBackend,
        }
    }
})
