

import {defineComponent, onMounted, PropType} from "vue";

export default defineComponent({
    name: "DialogFrame",
    props: {
        showDialog: Boolean,
        width: {
            type: [Number, String],
            default: 400,
        },
        height: {
            type: [Number, String],
            default: 400,
        },
        title: {
            type: String,
            default: "Untitled Dialog"
        },
        onClickClose: {
            type: Function,
            required: true,
        },
        closeByOverlayTouch: {
            type: Boolean,
            default: false,
        },
        hideCloseButton: {
            type: Boolean,
            default: false,
        }
    },
    setup(props) {

        let isSM = false

        const requestCloseDialog = () => {
            // console.log("requestCloseDialog");
            if (props.onClickClose && typeof props.onClickClose === "function") {
                props.onClickClose.call({})
            }
        };

        const onMouseDownInDialog = (ev: MouseEvent) => {
            ev.stopPropagation();
        }

        const onClickOverlay = () => {
            if (props.closeByOverlayTouch) {
                requestCloseDialog()
            }
        }

        const widthStyle = (): string => {

            if (typeof props.width === "number") {
                let w  = props.width
                if (w > window.innerWidth) {
                    w = window.innerWidth - 10
                }
                return w + "px"
            } else {
                return props.width
            }
        }

        const heightStyle = (): string => {
            if (typeof props.height === "number") {
                return props.height + "px"
            } else {
                return props.height
            }
        }

        onMounted(() => {
            isSM = window.innerWidth < 576
        })

        return {
            requestCloseDialog,
            onMouseDownInDialog,
            onClickOverlay,
            widthStyle,
            heightStyle,
        }
    },
})
