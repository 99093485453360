
import {defineComponent} from "vue";
import {toastStore} from "@/stores/toast-store";
import {FontSizes} from "@/utils/font-sizes";

export default defineComponent({
    name: "Toast",
    setup() {

        const toastTop = (): number => {
            return toastStore.state.show ? 40 : -40
        }

        return {
            toastStore,
            toastTop,
            FontSizes,
        }
    }
})
