import {createStore} from "vuex";

interface IConfirmDialogState {
    show: boolean;
    title: string;
    message: string;
    affirmativeText: string;
    affirmCallback: null | ((affirmed: boolean) => void),
}

export const confirmDialogStateStore = createStore<IConfirmDialogState>({
    state: {
        show: false,
        title: "",
        message: "",
        affirmativeText: "OK",
        affirmCallback: null,
    },
    mutations: {
        switchConfirmDialog: (state, payload) => {

            if (payload.show) {

                if (payload.title) {
                    state.title = payload.title
                }

                if (payload.message) {
                    state.message = payload.message
                }

                if (payload.affirmativeText) {
                    state.affirmativeText = payload.affirmativeText
                }

                if (payload.affirmCallback) {
                    state.affirmCallback = payload.affirmCallback
                }

            } else {
                state.message = ""
                state.affirmativeText = "OK"
                state.affirmCallback = null
            }
            state.show = payload.show
        },
        onConfirmed: ((state, payload) => {
            // console.log("onConfirmed in confirm dialog state store")
            state.show = false
            if (state.affirmCallback && typeof state.affirmCallback === "function") {
                state.affirmCallback(payload.affirmed)
            }
        })
    }
})