
import {defineComponent, onMounted, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import {backendApi} from "@/utils/backend-api";
import {Staff} from "@/data-models/staff";
import {logoutMessages, subPaths} from "@/utils/consts";
import {loadingOverlayStateStore} from "@/stores/loading-overlay-state-store";
import ClearableInput from "@/components/ClearableInput.vue";
import {FontSizes} from "@/utils/font-sizes";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import {downloadZIPFile} from "@/utils/file-utils";
import PopupMenu, {popupMenuItemType, popupPositionType} from "@/components/PopupMenu.vue";
import MenuButton from "@/components/MenuButton.vue";
import {confirmDialogStateStore} from "@/stores/confirm-dialog-state-store";
import {toastStore} from "@/stores/toast-store";

export default defineComponent({
    name: "Branch",
    components: { Breadcrumbs },
    setup() {

        const route = useRoute(),
            router = useRouter()


        onMounted(async () => {
            loadingOverlayStateStore.commit("fadeout")
        })


        return {
            FontSizes,
            ancestries: [
                {
                    name: "ホーム",
                    pathObj: subPaths.HOME
                }
            ],
        }
    }
})
