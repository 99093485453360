
import {defineComponent, onMounted, ref} from "vue";
import PDFViewer from "@/components/PDFViewer.vue";
import {addMonths, format} from "date-fns";
import ja from "date-fns/locale/ja";
import {loadingOverlayStateStore} from "@/stores/loading-overlay-state-store";
import {useRoute, useRouter} from "vue-router";
import {backendApi} from "@/utils/backend-api";
import {logoutMessages, subPaths} from "@/utils/consts";
import {useDevice} from "next-vue-device-detector";
import Breadcrumbs, {ancestryType} from "@/components/Breadcrumbs.vue";
import {authStore} from "@/stores/auth-store";
import {authLevels} from "@/data-models/auth";
import {Staff} from "@/data-models/staff";

export default defineComponent({
    name: "MonthInvoicePDFPage",
    components: {
        PDFViewer,
        Breadcrumbs,
    },
    setup() {

        const staffId = ref<number>(-1),
            staff = ref<Staff>(new Staff()),
            pdfSrc = ref<string>(""),
            pdfRowHeight = ref<string>(""),
            pdfColStyle = ref<any>({}),
            ancestries = ref<ancestryType[]>([] as ancestryType[])

        const router = useRouter()

        const update = async () => {

            const staffResp = await backendApi.loadStaffById(staffId.value)
            const pdfResp = await backendApi.inquireInvoicePDF(staffId.value, 1)

            if (staffResp.isAuthorized && pdfResp.isAuthorized) {

                staff.value = Staff.fromJson(staffResp.value)
                updateAncestries()

                pdfSrc.value = "data:application/pdf;charset=UTF-8;base64," + pdfResp.value.file_data
            } else {
                await router.push({
                    path: subPaths.LOGGED_OUT,
                    query: {
                        logout_message: logoutMessages.TOKEN_EXPIRED.key
                    }
                })
            }
        }

        const updateAncestries = () => {
            ancestries.value = [
                {
                    name: staff.value.displayName + "さん",
                    pathObj: {
                        path: subPaths.MY_PAGE,
                        query: {
                            staff_id: staff.value.staffId,
                        },
                    }
                },
                {
                    name: "月払い請求書発行",
                    pathObj: {
                        path: subPaths.MY_PAGE_INVOICE_OF_MONTH,
                        query: {
                            staff_id: staff.value.staffId,
                        },
                    }
                }
            ]

            if (authStore.getters.authLevel !== authLevels.DIST_STAFF) {
                ancestries.value.unshift({
                    name: staff.value.branch,
                    pathObj: {
                        path: subPaths.BRANCH_PAGE,
                        query: {
                            name: staff.value.branch
                        }
                    }
                })
                ancestries.value.unshift({
                    name: "ホーム",
                    pathObj: subPaths.HOME
                })
            }
        }

        const device = useDevice()

        onMounted(async () => {

            const stId = useRoute().query.staff_id as string
            if (stId) {
                staffId.value = parseInt(stId)
                await update()
            }

            if (device.android) {
                pdfRowHeight.value = "90%"

                pdfColStyle.value = {
                    width: "100%"
                }
            } else {
                pdfRowHeight.value = "1200px"
                pdfColStyle.value = {
                    width: "100%",
                    minWidth: "600px",
                }
            }

            loadingOverlayStateStore.commit("fadeout")
        })

        const onClickBackToMonthInvoicePage = () => {

            router.push({
                path: subPaths.MY_PAGE_INVOICE_OF_MONTH,
                query: {
                    staff_id: staff.value.staffId,
                },
            })
        }

        return {
            pdfSrc,
            pdfRowHeight,
            ancestries,
            onClickBackToMonthInvoicePage,
            pdfColStyle,
        }
    }
})
